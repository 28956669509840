import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Badge,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

function EstadoCuentaTraspasos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);


  const [mailTo, setMailTo] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [areas, setAreas] = useState("");
  const [nombreCliente, setNombreCliente] = useState([]);
  const [saldo, setSaldo] = useState(0);
  const [nombreComercialCliente, setNombreComercialCliente] = useState([]);
  const [claveCliente, setClaveCliente] = useState([]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [modalMail2, setModalMail2] = useState(false);
  const toggleMail2 = () => setModalMail2(!modalMail2);
  const [modalMail3, setModalMail3] = useState(false);
  const toggleMail3 = () => setModalMail3(!modalMail3);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalAgrupado, setModalAgrupado] = useState(false);
  const toggleAgrupado = () => setModalAgrupado(!modalAgrupado);

  const [activo, setActivo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [movimientos, setMovimientos] = useState([]);
  const [idCliente, setIdCliente] = useState("");
  const [saldoInicial, setSaldoInicial] = useState(0);
  const [plazo, setPlazo] = useState(0);

  const [totalTotalCargos, setTotalTotalCargos] = useState(0);
  const [totalTotalAbonos, setTotalTotalAbonos] = useState(0);
  const [totalTotalSaldo, setTotalTotalSaldo] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_AREAS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        // Array para pagination
        let arrayTabla = allClientes
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((a) => {
            if(a._id == "6201b1920f94f86068a741c6"){
              return {
                _id: a._id,
                area: a.name,
                cargos: parseFloat(a.cargos),
                abonos: parseFloat(a.abonos),
                saldo: parseFloat(a.saldo),
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);

        let car = dataFinal.map((c) => parseFloat(c.cargos));
        let TCar = car.reduce((t, cargos, index) => t + cargos, 0);
        setTotalTotalCargos(TCar);

        let abo = dataFinal.map((c) => parseFloat(c.abonos));
        let TAbo = abo.reduce((t, abonos, index) => t + abonos, 0);
        setTotalTotalAbonos(TAbo);

        let sal = dataFinal.map((c) => parseFloat(c.saldo));
        let TSal = sal.reduce((t, saldo, index) => t + saldo, 0);
        setTotalTotalSaldo(TSal);
        //
      })
      .catch((err) => {
        console.log(err);
      });


    let SI = movimientos
      .map((c) => {
        if (selectedFechaInicio) {
          if (
            (selectedFechaInicio == "" || selectedFechaInicio > c.fecha)
          ) {
            return c.cargo - c.abono;
          }
        } else {
          return 0;
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let TC = SI.reduce((t, total, index) => t + total, 0);

    setSaldoInicial(TC);
  }, [user, movimientos, selectedFechaInicio]);


  function PDFTabla() {
    const data = comments.map((a) => {
        return [
          a.area,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo),
        ];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Almacen",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalCargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalAbonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EstadosDeCuenta.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
        return {
          Almacen: a.area,
          Cargos: a.cargos,
          Abonos: a.abonos,
          Saldo: a.saldo,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "EstadoCuenta";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Almacen",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
        sheetHeader: [
          "Almacen",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
        return [
          a.area,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo),
        ];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Almacen",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalCargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalAbonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: "EstadoCuenta.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras los saldos de los clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Almacen", field: "area", sortable: true },
    { name: "Cargos", field: "cargos", sortable: true },
    { name: "Abonos", field: "abonos", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
    { name: "Estado Cuenta", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.cargos.toString().includes(search) ||
          comment.abonos.toString().includes(search) ||
          comment.saldo.toString().includes(search)
      );
    }
  
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idArea.includes(selectedArea)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cargos" &&
      sorting.field != "abonos" &&
      sorting.field != "saldo"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cargos" ||
        sorting.field == "abonos" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cargos" ||
        sorting.field == "abonos" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedArea,
  ]);


  async function jalaMovAgrupado(id, cliente, saldo) {
    await axios
      .get(`${URL_ESTADO_CUENTA}TiendaAgrupado/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setNombreCliente(cliente);
    setIdCliente(id);
    setSaldo(saldo);
    toggleAgrupado();
  }


 async function PDFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

   await axios
      .get(`${URL_DASHBOARDS}AntiguedadSaldosVencidoEdoCta/${idCliente}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allAntSaldos = response.data;

      let totalTreinta = 0;
      let totalSesenta = 0;
      let totalNoventa = 0;
      let totalMasNoventa = 0;
      let totalEnTiempo = 0;

      const dataAS = allAntSaldos.map((a) => {
        totalTreinta += isFinite(a.treinta) ? a.treinta : 0;
        totalSesenta += isFinite(a.sesenta) ? a.sesenta : 0;
        totalNoventa += isFinite(a.noventa) ? a.noventa : 0;
        totalMasNoventa += isFinite(a.masnoventa) ? a.masnoventa : 0;
        totalEnTiempo += isFinite(a.enTiempo) ? a.enTiempo : 0;
        const fechaVencimientoDate = new Date(a.fechaVencimiento);

        const fechaVencimientoFormatted = fechaVencimientoDate.toLocaleDateString('en-GB');
      
        const treintaFormatted = isFinite(a.treinta) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta) : '';
        const sesentaFormatted = isFinite(a.sesenta) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta) : '';
        const noventaFormatted = isFinite(a.noventa) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa) : '';
        const masnoventaFormatted = isFinite(a.masnoventa) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.masnoventa) : '';
        const enTiempoFormatted = isFinite(a.enTiempo) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo) : '';
      
        return [
          a.folio,
          fechaVencimientoFormatted,
          treintaFormatted,
          sesentaFormatted,
          noventaFormatted,
          masnoventaFormatted,
          enTiempoFormatted
        ];
      });
      const dataPDFAS = Object.values(dataAS);
      const dataPDFLimpiaAS = dataPDFAS.filter(function (el) {
        return el != null;
      });

      const totalVencido = totalTreinta + totalSesenta + totalNoventa + totalMasNoventa;

    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = a.cargo > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo): '';
        const abonos = a.abono > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono): '';
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.numero,
          a.fecha,
          a.movimiento,
          cargos,
          abonos,
          saldoParcialFormato,
          a.observaciones
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const columnStyles = [
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'}, //Cargos - Negro
      { fontSize: 9,textColor: ["#f40903"] ,halign: 'right'}, // Abonos - Rojo
      { fontSize: 9,textColor: ["#030af4"] ,halign: 'right'}, // Saldos - Azul a favor
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
  ];

    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    var img2 = new Image();
    const backgroundColor = ["#d1d2d4"];
    const backgroundColorBoxBlack = ["#231f20"];
    const backgroundColorBoxPurple = ["#f28029"];
    const margins = {  left: 5, right: 5 };

    img.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img, "png", 15, 5, 33, 33);
    
    doc.setFillColor(...backgroundColor);
    doc.setFontSize(12);
    doc.rect(5, 40, 200, 6, 'F');
    doc.setTextColor("#000000");
    doc.text(`Tel: 55 1234 5678`,8, 44);
    
    doc.setTextColor("#000000");
    doc.text(`Cliente: ${nombreComercialCliente}`,8, 52)
    doc.text(`Clave: ${claveCliente}`,176, 52)

    doc.setFillColor(...backgroundColorBoxBlack);
    doc.rect(100, 4, 105, 22, 'F');
    doc.setFillColor(...backgroundColorBoxPurple);
    doc.rect(74, 4, 25, 22, 'F');
    doc.setTextColor("#ffffff");
    doc.text(`Estado de Cuenta`,130, 15);
    doc.autoTable({
      head: [
        [
          "FOLIO",
          "FECHA",
          "MOVIMIENTO",
          "CARGOS",
          "ABONOS",
          "SALDO",
          "OBSERVACIONES"
        ],
      ],
      body: dataPDFLimpia,
      startY: 55,
      headStyles: {
        // fillColor: ["#f28029"],
        fillColor: ["#f28029"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      footStyles: {
        // fillColor: ["#f28029"],
        fillColor: ["#f28029"],
      },
      columnStyles: columnStyles,
      margin: margins,
      theme:"grid",
    });

    const columnStylesAntiguedad = [
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
  ];

    doc.addPage()
    doc.setFillColor(...backgroundColorBoxPurple);
    doc.rect(8, 8, 70, 10, 'F');
    doc.setFontSize(14);
    doc.setTextColor("#ffffff");
    doc.text(`ANTIGUEDAD DE SALDOS`,10, 15);
    doc.autoTable({
      head: [
        [
          "FOLIO",
          "FECHA DE VENCIMIENTO",
          "1 A 30 DIAS",
          "31 A 60 DIAS",
          "61 A 90 DIAS",
          "+ 90 DIAS",
          "EN TIEMPO",
        ],
      ],
      body: dataPDFLimpiaAS,
      startY: 20,
      headStyles: {
        fillColor: ["#f28029"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      footStyles: {
        fillColor: ["#f28029"],
      },
      columnStyles:columnStylesAntiguedad,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTreinta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalSesenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalNoventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalMasNoventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalEnTiempo),
        ]
      ],
      showFoot: "lastPage",
      margin: margins,
      theme:"grid",
    });

    let totalVencidos = totalTreinta+totalSesenta+totalNoventa+totalMasNoventa;
    let totalResumen = totalVencidos + totalEnTiempo;
    let countTotalAnt = dataAS.length;
    const totalesCadaColumna =countOfEachColumn(dataAS);
    let countVencidos =  totalesCadaColumna.slice(0, 4).reduce((acc, count) => acc + count, 0)
    let porcentajeVencidos = (countVencidos /countTotalAnt) ;
    let porcentajeAtiempo = (totalesCadaColumna[4] /countTotalAnt) ;
    let porcentajeTotal =porcentajeVencidos +porcentajeAtiempo;

    const resumenData = [
      ["Vencidos",
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalVencidos),
        new Intl.NumberFormat("en-US", {style: "percent",minimumFractionDigits: 2,maximumFractionDigits: 2}).format(porcentajeVencidos)],
      ["Al corriente",
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalEnTiempo),
        new Intl.NumberFormat("en-US", {style: "percent",minimumFractionDigits: 2,maximumFractionDigits: 2}).format(porcentajeAtiempo)],
      ["Total",
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalResumen),
        new Intl.NumberFormat("en-US", { style: "percent",minimumFractionDigits: 2,maximumFractionDigits: 2}).format(porcentajeTotal)]
    ];

    const columnStylesResumen = [
      { fontSize: 9,textColor: ["#FFFFFF"] ,fillColor: ["#f28029"],halign: 'center'},
      { fontSize: 9,textColor: ["#000000"],halign: 'right'},
      { fontSize: 9,textColor: ["#000000"],halign: 'center'},
  ];

    doc.autoTable({
      head: [
        [
          "",
          "RESUMEN DE SALDOS",
          ""
        ]
      ],
      body: resumenData,
      startY: doc.autoTable.previous.finalY + 10,
      headStyles: {
        fillColor: ["#f28029"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      columnStyles:columnStylesResumen,
      showFoot: "lastPage",
      margin:  {  left: 5, right: 120 },
      theme:"grid",
    });

    doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);

  })
  .catch((err) => {
    console.log(err);
  });
  }

  function countOfEachColumn(arr) {
  const columnCounts = [];

  for (let j = 2; j < arr[0].length; j++) {
    let columnCount = 0;

    for (let i = 0; i < arr.length; i++) {
      const currentValue = arr[i][j];
      if (currentValue > '') {
      columnCount += 1;
      }
    }

    columnCounts.push(columnCount);
  }

  return columnCounts ;
}


  function emailFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;
    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 50,
      headStyles: {
        fillColor: ["#f28029"],
        lineWidth: 0
      },
      footStyles: {
        fillColor: ["#f28029"],
      },
      theme:"grid",
      foot: [
        [
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail2();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function excelFOC() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Cargo: a.cargo,
          Abono: a.abono,
          Cargos: a.cargos,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }



  function emailFOCAgrupado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;
    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail3();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function excelFOCAgrupado() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Cargo: a.cargo,
          Abono: a.abono,
          Cargos: a.cargos,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  let totalCargosTabla = 0;
  let totalAbonosTabla = 0;
  let totalSaldoTabla = 0;

  let saldoParcialTabla = saldoInicial;
  let totalCargosEdo = 0;
  let totalAbonosEdo = 0;
  let totalSaldoEdo = 0;

  let saldoParcialTablaAgrupado = saldoInicial;
  let totalCargosEdoAgrupado = 0;
  let totalAbonosEdoAgrupado = 0;
  let totalSaldoEdoAgrupado = 0;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const secondDate = new Date(hoy);
  let saldoVencido =0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.edoCtaClientes ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <h3 align="center">Estado de Cuenta</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                totalCargosTabla = totalCargosTabla + parseFloat(p.cargos);
                totalAbonosTabla = totalAbonosTabla + parseFloat(p.abonos);
                totalSaldoTabla = totalSaldoTabla + parseFloat(p.saldo);
                {
                  return (
                    <tr>
                      <td>{p.area}</td>
                      <td>
                         {p.cargos > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(p.cargos): ''}
                      </td>
                      <td>
                        {p.abonos > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(p.abonos): ''}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(p.saldo)}
                      </td>
                      <td>
                       

                        <Button
                          color="success"
                          size="sm"
                          onClick={(e) =>
                            jalaMovAgrupado(p._id, p.cliente, p.saldo)
                          }
                          id="Detalle"
                        >
                          <i class="far fa-newspaper"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td className="negrita" align="right">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalSaldoTabla)}
                </td>
                <td></td>
              </tr>

              {/* <tr>
                <td className="negrita" align="right">
                  TOTAL GENERAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalCargos)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalAbonos)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalSaldo)}
                </td>
                <td></td>
              </tr> */}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xxl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={2}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOC}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail2}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOC}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>


            <Col md={3}>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Fecha</Label>
                </Col>
                <Col md={6}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>


            <Col md={2}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>
          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla">Folio</th>
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                  <th className="tituloTabla">Observaciones</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="right">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                    </td>
                  </tr>
                ) : undefined}
                {movimientos.map((c) => {
                  if (
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha)
                  ) {
                    saldoParcialTabla = saldoParcialTabla + c.cargo - c.abono;
                    totalCargosEdo = totalCargosEdo + c.cargo;
                    totalAbonosEdo = totalAbonosEdo + c.abono;
                    totalSaldoEdo = totalCargosEdo - totalAbonosEdo;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.ceil((secondDate - firstDate) / oneDay))
                    if((c.movimiento == "Nota" || c.movimiento == "Nota Cargo") && c.saldo > 0 &&  diffDays > plazo){
                      saldoVencido = 0
                      saldoVencido = saldoVencido + c.saldo
                      return (
                        <tr style={{backgroundColor:"#ed8c8c"}}>
                          <td align="center">{c.numero}</td>
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="right">
                            {c.cargo > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo): ''}
                          </td>
                          <td align="right">
                            {c.abono > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono): ''}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                saldoParcialTabla
                              )}
                          </td>
                          <td align="center">{c.observaciones}</td>
                        </tr>
                      )
                    }else{
                    return (
                      <tr>
                        <td align="center">{c.numero}</td>
                        <td align="center">{c.fecha}</td>
                        <td align="center">{c.movimiento}</td>
                        <td align="right">
                          {c.cargo > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo): ''}
                        </td>
                        <td align="right">
                          {c.abono > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono): ''}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              saldoParcialTabla
                            )}
                        </td>
                        <td align="center">{c.observaciones}</td>
                      </tr>
                    );}
                  }
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Anterior
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdo)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {"-" + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdo)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Vencido
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoVencido)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Actual
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>



      <Modal size="xxl" isOpen={modalAgrupado} toggle={toggleAgrupado}>
        <ModalHeader toggle={toggleAgrupado}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={2}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOC}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail3}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOCAgrupado}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>

            <Col md={3}>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Fecha</Label>
                </Col>
                <Col md={6}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>

          

            <Col md={3}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>
          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Numero</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="right">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                    </td>
                  </tr>
                ) : undefined}
                {movimientos.map((c) => {
                  if (
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha)
                  ) {
                    saldoParcialTablaAgrupado = saldoParcialTablaAgrupado + c.cargo - c.abono;
                    totalCargosEdoAgrupado = totalCargosEdoAgrupado + c.cargo;
                    totalAbonosEdoAgrupado = totalAbonosEdoAgrupado + c.abono;
                    totalSaldoEdoAgrupado = totalCargosEdoAgrupado - totalAbonosEdoAgrupado;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.ceil((secondDate - firstDate) / oneDay))
                    if((c.movimiento == "Nota" || c.movimiento == "Nota Cargo") && c.saldo > 0 &&  diffDays > plazo){
                      saldoVencido = 0
                      saldoVencido = saldoVencido + c.saldo
                      return (
                        <tr style={{backgroundColor:"#ed8c8c"}}>
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="center">{c.numero}</td>
                          <td align="right">
                            {c.cargo > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo): ''}
                          </td>
                          <td align="right">
                            {c.abono > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono): ''}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                saldoParcialTablaAgrupado
                              )}
                          </td>
                        </tr>
                      )
                    }else{
                    return (
                      <tr>
                        <td align="center">{c.fecha}</td>
                        <td align="center">{c.movimiento}</td>
                        <td align="center">{c.numero}</td>
                        <td align="right">
                          {c.cargo > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo): ''}
                        </td>
                        <td align="right">
                          {c.abono > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono): ''}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              saldoParcialTablaAgrupado
                            )}
                        </td>
                      </tr>
                    );}
                  }
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Anterior
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoAgrupado)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {"-" + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoAgrupado)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Vencido
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoVencido)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Actual
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleAgrupado}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>



      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
        <ModalHeader toggle={toggleMail}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={enviaMail}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalMail2} toggle={toggleMail2}>
        <ModalHeader toggle={toggleMail2}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOC}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalMail3} toggle={toggleMail3}>
        <ModalHeader toggle={toggleMail3}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOCAgrupado}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default EstadoCuentaTraspasos;
