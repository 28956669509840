import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

function ListadoArticulos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PROPORCION = process.env.REACT_APP_URL_ARTICULOS_PROPORCION;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_COMPOSICIONES = process.env.REACT_APP_URL_COMPOSICIONES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;


  const [activo, setActivo] = useState("Si");
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [piezasPorCaja, setPiezasPorCaja] = useState("");
  const [pesoCaja, setPesoCaja] = useState(0);
  const [precioTienda, setPrecioTienda] = useState(0);
  const [tamanoCaja, setTamanoCaja] = useState("");
  const [costoUSD, setCostoUSD] = useState("");
  const [costoMX, setCostoMX] = useState("");
  const [venta, setVenta] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [lineas, setLineas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [composiciones, setComposiciones] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [ano, setAno] = useState("");
  const [EAN, setEAN] = useState("");
  const [vendedores, setVendedores] = useState("");
  const [selectedCodigo, setSelectedCodigo] = useState(0);
  const [selectedNombre, setSelectedNombre] = useState(0);
  const [selectedLinea, setSelectedLinea] = useState(0);
  const [selectedMarca, setSelectedMarca] = useState(0);
  const [selectedFamilia, setSelectedFamilia] = useState(0);
  const [selectedColeccion, setSelectedColeccion] = useState(0);
  const [selectedComposicion, setSelectedComposicion] = useState(0);
  const [selectedProveedor, setSelectedProveedor] = useState(0);
  const [selectedAno, setSelectedAno] = useState(0);
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");

  const [selectedLineaEdit, setSelectedLineaEdit] = useState("");
  const [selectedMarcaEdit, setSelectedMarcaEdit] = useState("");
  const [selectedFamiliaEdit, setSelectedFamiliaEdit] = useState("");
  const [selectedColeccionEdit, setSelectedColeccionEdit] = useState("");
  const [selectedComposicionEdit, setSelectedComposicionEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");
  const [selectedEmpresaEdit, setSelectedEmpresaEdit] = useState("");
  const [idEditArticulo, setIdEditArticulo] = useState("");

  const [validaBoton, setValidaBoton] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProporcion, setModalProporcion] = useState(false);
  const toggleProporcion = () => setModalProporcion(!modalProporcion);
  const [proporcion, setProporcion] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const [arrayColores, setArrayColores] = useState([]);
  const [arrayColoresNew, setArrayColoresNew] = useState([]);
  const [arrayTallasNew, setArrayTallasNew] = useState([]);


  const [modalDuplicar, setModalDuplicar] = useState(false);
  const toggleDuplicar = () => setModalDuplicar(!modalDuplicar);
  const [idDuplicar, setidDuplicar] = useState([])
  const [codigoDuplicar, setcodigoDuplicar] = useState("")
  const [nombreDuplicar, setnombreDuplicar] = useState("")
  const [descripcionDuplicar, setdescripcionDuplicar] = useState("")
  const [pesoCajaDuplicar, setpesoCajaDuplicar] = useState(0)
  const [precioTiendaDuplicar, setprecioTiendaDuplicar] = useState(0)
  const [tamanoCajaDuplicar, settamanoCajaDuplicar] = useState("")
  const [piezasPorCajaDuplicar, setpiezasPorCajaDuplicar] = useState(0)
  const [costoUSDDuplicar, setcostoUSDDuplicar] = useState(0)
  const [costoMXDuplicar, setcostoMXDuplicar] = useState(0)
  const [ventaDuplicar, setventaDuplicar] = useState(0)
  const [observacionesDuplicar, setobservacionesDuplicar] = useState("NA")
  const [lineasDuplicar, setlineasDuplicar] = useState("")
  const [marcasDuplicar, setmarcasDuplicar] = useState("")
  const [familiasDuplicar, setfamiliasDuplicar] = useState("")
  const [coleccionesDuplicar, setcoleccionesDuplicar] = useState("")
  const [composicionesDuplicar, setcomposicionesDuplicar] = useState("")
  const [proveedoresDuplicar, setproveedoresDuplicar] = useState("")
  const [empresasDuplicar, setempresasDuplicar] = useState("")
  const [anoDuplicar, setanoDuplicar] = useState("")
  const [EANDuplicar, setEANDuplicar] = useState("")
  const [vendedoresDuplicar, setvendedoresDuplicar] = useState("")
  const [coloresDuplicar, setcoloresDuplicar] = useState("")
  const [tallasDuplicar, settallasDuplicar] = useState("")
  const [foto, setFoto] = useState("")
  const [proporcionDuplicar, setProporcionDuplicar] = useState([]);

  const [arrayTallas, setArrayTallas] = useState([])
  const [colores, setColores] = useState([])
  const [tallas, setTallas] = useState([])
  const [selectedColor, setSelectedColor] = useState("")
  const [selectedTalla, setSelectedTalla] = useState("")

  const [inputFields, setInputFields] = useState([]);

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 1825);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(12), (val, index) => index + year);

  const [filesMasiva, setFilesMasiva] = useState([]);
  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        // Array para pagination
        let arrayTabla = allArticulos
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              activo: a.is_active,
              codigo: a.codigo,
              nombre: a.nombre,
              piezasPorCaja: a.piezasPorCaja,
              pesoCaja: a.pesoCaja,
              precioTienda: a.precioTienda,
              tamanoCaja: a.tamanoCaja,
              costoUSD: a.costoUSD,
              costoMX: a.costoMX,
              venta: a.venta,
              observaciones: a.observaciones,
              linea: a.lineas[0].name,
              marca: a.marcas[0].name,
              familia: a.familias[0].name,
              coleccion: a.colecciones[0].name,
              composicion: a.composiciones[0].name,
              proveedor: a.proveedores[0].nombre_comercial,
              idLinea: a.lineas[0]._id,
              idMarca: a.marcas[0]._id,
              idFamilia: a.familias[0]._id,
              idColeccion: a.colecciones[0]._id,
              idComposicion: a.composiciones[0]._id,
              idProveedor: a.proveedores[0]._id,
              ano: a.ano,
              EAN: a.EAN,
              vendedores: a.vendedores,
              colores: a.colores,
              tallas: a.tallas,
              descripcion: a.descripcion,
              createdAt:a.createdAt,
              fecha: moment(a.createdAt).utcOffset('-0500').format("YYYY-MM-DD"),
              empresa: a.empresas[0].name,
              idEmpresa: a.empresas[0]._id,
              foto: a.foto
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  
      axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allEmpresas = res.data;
        setEmpresas(allEmpresas);
      })
      .catch((err) => {
        console.log(err);
      })
    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMarcas = res.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COMPOSICIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allComposiciones = res.data;
        setComposiciones(allComposiciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedLinea == 0 || selectedLinea == a.idLinea) &&
        (selectedMarca == 0 || selectedMarca == a.idMarca) &&
        (selectedFamilia == 0 || selectedFamilia == a.idFamilia) &&
        (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
        (selectedComposicion == 0 || selectedComposicion == a.idComposicion) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedor) &&
        (selectedAno == 0 || selectedAno == a.ano) &&
        (activo == 0 || activo == a.activo)
      ) {
        return [
          a.codigo,
          a.nombre,
          a.piezasPorCaja,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoUSD),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoMX),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.venta),
          a.linea,
          a.marca,
          a.familia,
          a.coleccion,
          a.composicion,
          a.proveedor,
          a.ano,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Modelos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Pzas x Caja",
          "FOB USD",
          "Costo MX",
          "Venta",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "Composicion",
          "Proveedor",
          "Año",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
    });
    doc.save(`Modelos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedLinea == 0 || selectedLinea == a.idLinea) &&
        (selectedMarca == 0 || selectedMarca == a.idMarca) &&
        (selectedFamilia == 0 || selectedFamilia == a.idFamilia) &&
        (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
        (selectedComposicion == 0 || selectedComposicion == a.idComposicion) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedor) &&
        (selectedAno == 0 || selectedAno == a.ano) &&
        (activo == 0 || activo == a.activo) 
        // &&
        // (a.createdAt > "2024-03-06T05:21:38.068+0000")
      ) {
        return {
          ID:a._id,
          Codigo: a.codigo,
          Nombre: a.nombre,
          PiezasPorCaja: a.piezasPorCaja,
          FOBUSD: a.costoUSD,
          CostoMX: a.costoMX,
          Venta: a.venta,
          Linea: a.linea,
          Marca: a.marca,
          Familia: a.familia,
          Coleccion: a.coleccion,
          Composicion: a.composicion,
          Proveedor: a.proveedor,
          Año: a.ano,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoModelos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoModelos",
        sheetFilter: [
          "ID",
          "Codigo",
          "Nombre",
          "PiezasPorCaja",
          "FOBUSD",
          "CostoMX",
          "Venta",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "Composicion",
          "Proveedor",
          "Año",
        ],
        sheetHeader: [
          "ID",
          "Codigo",
          "Nombre",
          "PiezasPorCaja",
          "FOBUSD",
          "CostoMX",
          "Venta",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "Composicion",
          "Proveedor",
          "Año",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedLinea == 0 || selectedLinea == a.idLinea) &&
        (selectedMarca == 0 || selectedMarca == a.idMarca) &&
        (selectedFamilia == 0 || selectedFamilia == a.idFamilia) &&
        (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
        (selectedComposicion == 0 || selectedComposicion == a.idComposicion) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedor) &&
        (selectedAno == 0 || selectedAno == a.ano) &&
        (activo == 0 || activo == a.activo)
      ) {
        return [
          a.codigo,
          a.nombre,
          a.piezasPorCaja,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoUSD),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoMX),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.venta),
          a.linea,
          a.marca,
          a.familia,
          a.coleccion,
          a.composicion,
          a.proveedor,
          a.ano,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Modelos`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Pzas x Caja",
          "FOB USD",
          "Costo MX",
          "Venta",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "Composicion",
          "Proveedor",
          "Año",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Modelos",
          email: mailTo,
          fileName: "ListadoModelos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Modelos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  
  function jalaFoto(
    idEdit
  ) {
    setPhoto("")
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));


    setIdEditArticulo(idEdit);
    toggleFoto();
  }


  function jalaInfo(
    codigo,
    nombre,
    piezasPorCaja,
    pesoCaja,
    precioTienda,
    tamanoCaja,
    costoUSD,
    costoMX,
    venta,
    observaciones,
    idLinea,
    idMarca,
    idFamilia,
    idColeccion,
    idComposicion,
    idProveedor,
    idEmpresa,
    ano,
    EAN,
    vendedores,
    idEdit
  ) {
    setCodigo(codigo);
    setNombre(nombre);
    setPiezasPorCaja(piezasPorCaja);
    setPesoCaja(pesoCaja);
    setPrecioTienda(precioTienda);
    setTamanoCaja(tamanoCaja);
    setCostoUSD(costoUSD);
    setCostoMX(costoMX);
    setVenta(venta);
    setObservaciones(observaciones);
    setSelectedLineaEdit(idLinea);
    setSelectedMarcaEdit(idMarca);
    setSelectedFamiliaEdit(idFamilia);
    setSelectedColeccionEdit(idColeccion);
    setSelectedComposicionEdit(idComposicion);
    setSelectedProveedorEdit(idProveedor);
    setSelectedEmpresaEdit(idEmpresa);
    setAno(ano);
    setEAN(EAN);
    setVendedores(vendedores);
    setIdEditArticulo(idEdit);
    toggleEdit();
  }

  function editArticulos(event) {
    event.preventDefault();
    const URL_ARTICULOS_EDIT = `${process.env.REACT_APP_URL_ARTICULOS}/${idEditArticulo}`;
    if (file == null) {

        axios.patch(
          URL_ARTICULOS_EDIT,
          {
            codigo,
            nombre,
            piezasPorCaja,
            pesoCaja,
            precioTienda,
            tamanoCaja,
            costoUSD,
            costoMX,
            venta,
            lineas: selectedLineaEdit,
            marcas: selectedMarcaEdit,
            familias: selectedFamiliaEdit,
            colecciones: selectedColeccionEdit,
            composiciones: selectedComposicionEdit,
            proveedores: selectedProveedorEdit,
            empresas: selectedEmpresaEdit,
            ano,
            EAN,
            vendedores
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Articulo",
            detalle: `${nombre} ${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    } else{
        axios.patch(
          URL_ARTICULOS_EDIT,
          {
            codigo,
            nombre,
            piezasPorCaja,
            pesoCaja,
            precioTienda,
            tamanoCaja,
            costoUSD,
            costoMX,
            venta,
            lineas: selectedLineaEdit,
            marcas: selectedMarcaEdit,
            familias: selectedFamiliaEdit,
            colecciones: selectedColeccionEdit,
            composiciones: selectedComposicionEdit,
            proveedores: selectedProveedorEdit,
            empresas: selectedEmpresaEdit,
            ano,
            EAN,
            vendedores,
            foto: "Si"         
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("articulo", idEditArticulo);

        axios
          .post(URL_FILEPOST, formData, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Articulo",
                detalle: `${nombre} ${codigo}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            );

            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }
}
  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Pzas Caja", field: "piezasPorCaja", sortable: true },
    { name: "FOB USD", field: "costoUSD", sortable: true },
    { name: "Costo MX", field: "costoMX", sortable: true },
    { name: "Venta", field: "venta", sortable: true },
    { name: "Linea", field: "linea", sortable: true },
    { name: "Marca", field: "marca", sortable: true },
    { name: "Familia", field: "familia", sortable: true },
    { name: "Coleccion", field: "coleccion", sortable: true },
    { name: "Composicion", field: "composicion", sortable: true },
    { name: "Proveedor", field: "proveedor", sortable: true },
    { name: "Empresa", field: "empresa", sortable: true },
    { name: "Año", field: "ano", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment._id.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.linea.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.familia.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
          comment.composicion.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.empresa.toLowerCase().includes(search.toLowerCase()) ||
          comment.piezasPorCaja.toString().includes(search) ||
          comment.costoUSD.toString().includes(search) ||
          comment.costoMX.toString().includes(search) ||
          comment.venta.toString().includes(search) ||
          comment.ano.toString().includes(search)
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedCodigo)
      );
    }
    if (selectedNombre) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedNombre)
      );
    }
    if (selectedLinea) {
      computedComments = computedComments.filter((e) =>
        e.idLinea.includes(selectedLinea)
      );
    }
    if (selectedMarca) {
      computedComments = computedComments.filter((e) =>
        e.idMarca.includes(selectedMarca)
      );
    }
    if (selectedFamilia) {
      computedComments = computedComments.filter((e) =>
        e.idFamilia.includes(selectedFamilia)
      );
    }
    if (selectedColeccion) {
      computedComments = computedComments.filter((e) =>
        e.idColeccion.includes(selectedColeccion)
      );
    }
    if (selectedComposicion) {
      computedComments = computedComments.filter((e) =>
        e.idComposicion.includes(selectedComposicion)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedor.includes(selectedProveedor)
      );
    }
    if (selectedEmpresa) {
      computedComments = computedComments.filter((e) =>
        e.idEmpresa.includes(selectedEmpresa)
      );
    }
    if (selectedAno) {
      computedComments = computedComments.filter((e) =>
        e.ano.includes(selectedAno)
      );
    }

    if (foto) {
      computedComments = computedComments.filter((e) =>
        e.foto.includes(foto)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "piezasPorCaja" &&
      sorting.field != "costoUSD" &&
      sorting.field != "costoMX" &&
      sorting.field != "venta"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "piezasPorCaja" ||
        sorting.field == "costoUSD" ||
        sorting.field == "costoMX" ||
        sorting.field == "venta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "piezasPorCaja" ||
        sorting.field == "costoUSD" ||
        sorting.field == "costoMX" ||
        sorting.field == "venta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCodigo,
    selectedNombre,
    selectedLinea,
    selectedMarca,
    selectedFamilia,
    selectedColeccion,
    selectedComposicion,
    selectedProveedor,
    selectedEmpresa,
    selectedAno,
    activo,
    foto
  ]);

 async function QRGen(
    idArt,
    codigo,
    nombre,
    piezasPorCaja,
    linea,
    marca,
    familia,
    coleccion,
    ano,
    composicion,
    venta
  ) {

    await  axios
    .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idArt}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allPorporcion = res.data.filter((porcion) => porcion.cantidad !== "0");
    const doc = new jsPDF("landscape", "mm", [100, 60], true);

    
    const tallas = Array.from(
      new Set(allPorporcion.flatMap((porcion) => porcion.tallas.map((talla) => talla.name)))
    );

    const customSizeOrder = ["EECH", "ECH","CHICA", "MEDIANA", "GRANDE", "EXTRA GRANDE", "2EG", "3EG", "4EG", "5EG","6EG","1X","2X","3X","4X","5X","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "5/6", "7/8", "10/12", "14/16"]; // Add other sizes as needed
    const sortedTallas = tallas.sort((a, b) => {
      return customSizeOrder.indexOf(a) - customSizeOrder.indexOf(b);
    });

    // Define columns (tallas)
    const columns = ["", ...sortedTallas];

    // Group data by color
    const groupedData = {};
    allPorporcion.forEach((porcion) => {
      const colorName = porcion.colores[0].name;
      if (!groupedData[colorName]) {
        groupedData[colorName] = {};
      }
      groupedData[colorName][porcion.tallas[0].name] = porcion.cantidad || 0;
    });

    // Define rows (colores, cantidad)
    const rows = Object.keys(groupedData).map((colorName) => {
      const rowData = [colorName];
      tallas.forEach((talla) => {
        rowData.push(groupedData[colorName][talla] || 0);
      });
      return rowData;
    });

    var base64 = qrcode.toDataURL(idArt);
    var img3 = new Image();
    img3.src = base64;

    doc.setFontSize(8).setFont(undefined, 'bold')
    doc.text(`MODELO: ${codigo}`, 5, 5);
    doc.setFontSize(6)
    doc.text(`Piezas X Caja: ${piezasPorCaja}`, 45, 5);
    doc.text(`REF-13 ${venta}`, 70, 5);

    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 7,
      margin: { left: .5, right: .5 },
      // theme: "grid",
      headStyles: {
        fillColor: false,
        textColor: [0, 0, 0],
        fontStyle: 'bold',
        lineWidth: 0
        },
      styles: {
        fontStyle: 'bold',
        fontSize: 5,
        cellPadding: .7,
        lineWidth: .3,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0]
      },
    })

    doc.addPage()
    doc.addImage(img3, "png", 30, 5, 40, 40);

    doc.save(`QR.pdf`);

    })
    .catch((err) => {
      console.log(err);
    });
    
  }

function jalaProporcion(
  idEdit,
  codigo,
  nombre,
  tallas
  ) {
  setIdEditArticulo(idEdit);
  setCodigo(codigo);
  setNombre(nombre);

  axios
  .get(`${URL_ARTICULOS_PROPORCION}Articulos/${idEdit}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((res) => {
    const allProporcion = res.data;
    let arrayTabla = allProporcion.map((i) => {
      return {
        _id: i._id,
        size: i.tallas[0].name,
        sizeId: i.tallas[0]._id,
        color: i.colores[0].name,
        colorId: i.colores[0]._id,
        amount: i.cantidad,
      };
    })
    .filter(function (el) {
      return el != null;
    });

  let dataFinal = Object.values(arrayTabla);
  

// setArrayTallas(tallas)

// Setear Colores
let uniqueColors = {}
let arrayTablaColores = allProporcion.map((i) => {
  return {
    name: i.colores[0].name,
    id: i.colores[0]._id,
  };
})
.filter(function (el) {
  if (el && !uniqueColors[el.id]) {
    uniqueColors[el.id] = true;
    return true;
  }
  return false;
});

let dataFinalColores = Object.values(arrayTablaColores);

setArrayColores(dataFinalColores)

// Setear Tallas
let uniqueTallas = {}
let arrayTablaTallas = allProporcion.map((i) => {
  return {
    name: i.tallas[0].name,
    id: i.tallas[0]._id,
  };
})
.filter(function (el) {
  if (el && !uniqueTallas[el.id]) {
    uniqueTallas[el.id] = true;
    return true;
  }
  return false;
});

let dataFinalTallas = Object.values(arrayTablaTallas);

setArrayTallas(dataFinalTallas)

  function customSort(a, b) {
    // First, compare colors
    const colorComparison = a.color.localeCompare(b.color);
  
    // If colors are the same, compare sizes
    if (colorComparison === 0) {
      const sizeOrder = {
        "CHICA": 0,
        "MEDIANA": 1,
        "GRANDE": 2,
        "EXTRA GRANDE": 3,
        "2EG": 4,
        "3EG": 5,
        "4EG": 6,
        "5EG": 7,
        "6EG": 8,
        "1X": 9,
        "2X": 10,
        "3X": 11,
        "4X": 12,
        "5X": 13,
        "6": 14,
        "7": 15,
        "8": 16,
        "9": 17,
        "10": 18,
        "11": 19,
        "12": 20,
        "13": 21,
        "14": 22,
        "15": 23,
        "16": 24,
        "17": 25,
        "18": 26,
        "19": 27,
        "20": 28,
        "21": 29,
        "22": 30,
        "23": 31,
        "24": 32,
        "25": 33,
        "26": 34,
        "27": 35,
        "28": 36,
        "29": 37,
        "30": 38,
        "31": 39,
        "32": 40,
        "33": 41,
        "34": 42,
        "35": 43,
        "36": 44,
        "37": 45,
        "38": 46,
        "39": 47,
        "40": 48,
        "41": 49,
        "42": 50,
        "43": 51,
        "44": 52,
        "45": 53,
        "46": 54,
        "47": 55,
        "48": 56,
        "49": 57,
        "50": 58,
        "5/6": 59,
        "7/8": 60,
        "10/12": 61,
        "14/16": 62,
      };
      
      return sizeOrder[a.talla] - sizeOrder[b.talla];
    }
  
    return colorComparison;
  }
  
  // Sort the array using the custom sort function
  const sortedData = dataFinal.sort(customSort);

    setAmounts(sortedData);
    toggleProporcion();
  })
  .catch((err) => console.log(err));


}


const handleInputChange = (sizeId, colorId, value) => {
  const updatedAmounts = [...amounts];
  const existingEntryIndex = updatedAmounts.findIndex(entry => entry.sizeId === sizeId && entry.colorId === colorId);

  if (existingEntryIndex !== -1) {
    updatedAmounts[existingEntryIndex].amount = value;
  } else {
    updatedAmounts.push({ sizeId, colorId, amount: value });
  }

  setAmounts(updatedAmounts);
};

const handleInputChangeNew = (sizeId, colorId, value) => {
  const updatedAmounts = [...inputFields];
  const existingEntryIndex = updatedAmounts.findIndex(entry => entry.sizeId === sizeId && entry.colorId === colorId);

  if (existingEntryIndex !== -1) {
    updatedAmounts[existingEntryIndex].amount = value;
  } else {
    updatedAmounts.push({ sizeId, colorId, amount: value });
  }

  setInputFields(updatedAmounts);
};



function editProporcion(){
  let total = amounts.length + inputFields.length
  amounts.map((a) => {
    axios
    .patch(`${URL_ARTICULOS_PROPORCION}/${a._id}`,{
      cantidad: a.amount,
    }, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
       total = total - 1
       if(total == 0){
         Swal.fire({
           icon: 'success',
           title: 'Se actualizo correctamente',
           showConfirmButton: false,
           timer: 1500
         })
       }
      })
      .catch((err) => {
        console.log(err);
      });
  })

  inputFields.map((a)=>{
    if(a.amount>0){
    axios.post(
      URL_ARTICULOS_PROPORCION,
      {
        articulos: idEditArticulo,
        cantidad: a.amount,
        colores: a.colorId,
        tallas: a.sizeId
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
        },
      }
    ).then(()=>{
      total = total - 1
      if(total == 0){
        axios.post(
          URL_LOGS,
          {
            tipo: "Agregar Color Proporcion",
            detalle: `${idEditArticulo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
              )}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", `success`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      
    }).catch((error)=>{
      console.log(error)
      setValidaBoton(true)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
    })
  }else{
    total = total - 1
    if(total == 0){
      axios.post(
        URL_LOGS,
        {
          tipo: "Agregar Color Proporcion",
          detalle: `${idEditArticulo}`,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
          },
        }
      );
      Swal.fire("Good job!", "Creado con exito", `success`);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }
  })
}

    function jalaInfoDuplicar(id){

      setidDuplicar(id);

      comments.map((a) => {
        if(a._id == id){
      // setcodigoDuplicar(a.codigo)
      setnombreDuplicar(a.nombre)
      setdescripcionDuplicar(a.descripcion)
      setpesoCajaDuplicar(a.pesoCaja)
      setprecioTiendaDuplicar(a.precioTienda)
      settamanoCajaDuplicar(a.tamanoCaja)
      setpiezasPorCajaDuplicar(a.piezasPorCaja)
      setcostoUSDDuplicar(a.costoUSD)
      setcostoMXDuplicar(a.costoMX)
      setventaDuplicar(a.venta)
      setobservacionesDuplicar(a.observaciones)
      setlineasDuplicar(a.idLinea)
      setmarcasDuplicar(a.idMarca)
      setfamiliasDuplicar(a.idFamilia)
      setcoleccionesDuplicar(a.idColeccion)
      setcomposicionesDuplicar(a.idComposicion)
      setproveedoresDuplicar(a.idProveedor)
      setempresasDuplicar(a.idEmpresa)
      setanoDuplicar(a.ano)
      setEANDuplicar(a.EAN)
      setvendedoresDuplicar(a.vendedores)
      setcoloresDuplicar(a.colores)
      settallasDuplicar(a.tallas)
    }})

    axios
    .get(`${URL_ARTICULOS_PROPORCION}Articulos/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allProporcion = res.data;
    
      setProporcionDuplicar(allProporcion);
    })
    .catch((err) => console.log(err));


    }

    const saveDuplicar = async (event) => {
      setValidaBoton(false);
  
      const arrayTallasOfIds = tallasDuplicar.map(talla => talla._id)
      const arrayColoresOfIds = coloresDuplicar.map(c => c._id)
      
  let totalProporcion = proporcionDuplicar.length
      try {
        await axios
          .post(
            URL_ARTICULOS,
            {
              codigo: codigoDuplicar,
              nombre: nombreDuplicar,
              descripcion: descripcionDuplicar,
              pesoCaja: pesoCajaDuplicar,
              precioTienda: precioTiendaDuplicar,
              tamanoCaja: tamanoCajaDuplicar,
              piezasPorCaja: piezasPorCajaDuplicar,
              costoUSD: costoUSDDuplicar,
              costoMX: costoMXDuplicar,
              venta: ventaDuplicar,
              observaciones: observacionesDuplicar,
              lineas: lineasDuplicar,
              marcas: marcasDuplicar,
              familias: familiasDuplicar,
              colecciones: coleccionesDuplicar,
              composiciones: composicionesDuplicar,
              proveedores: proveedoresDuplicar,
              empresas: empresasDuplicar,
              ano: anoDuplicar,
              EAN: EANDuplicar,
              vendedores: vendedoresDuplicar,
              colores: arrayColoresOfIds,
              tallas: arrayTallasOfIds,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            axios
              .post(
                URL_INVENTARIOS,
                {
                  articulos: data.data._id,
                  areas: AREAS_CEDIS,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then(() => {
                if(proporcionDuplicar.length > 0){
                proporcionDuplicar.map((a)=>{
                  axios.post(
                    URL_ARTICULOS_PROPORCION,
                    {
                      articulos: data.data._id,
                      cantidad: a.cantidad,
                      colores: a.colores[0]._id,
                      tallas: a.tallas[0]._id
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  ).then(()=>{
                    totalProporcion = totalProporcion - 1
                    if(totalProporcion === 0){
                      axios.post(
                        URL_LOGS,
                        {
                          tipo: "Duplicar Modelo",
                          detalle: `${nombreDuplicar} ${codigoDuplicar}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      );
                      Swal.fire("Good job!", "Creado con exito", `success`);
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    }
                    
                  }).catch((error)=>{
                    console.log(error)
                    setValidaBoton(true)
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                  })
                })
              } else{
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Duplicar modelos",
                    detalle: `${nombreDuplicar} ${codigoDuplicar}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
            setValidaBoton(true);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    };



    const addColor = () => {
      if(selectedColor == ""){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Selecciona un color!",
        });
        return
      }
      let c = "";
      let foundColor = colores.find(a => a._id === selectedColor);
      
      if (foundColor) {
        c = foundColor.name;
      }


      let add = arrayTallas.map((a) => ({
        id: uuidv4(),
        colorId: selectedColor,
        color: c,
        sizeId: a.id,
        size: a.name,
        amount: 0
      }));

      setInputFields([
        ...inputFields, ...add
      ]);

      let colNew = [...arrayColoresNew, { id: selectedColor, name: c }];
      setArrayColoresNew(colNew);
    };

    const addTalla = () => {
      if(selectedTalla == ""){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Selecciona un Talla!",
        });
        return
      }
      let c = "";
      let foundTalla = tallas.find(a => a._id === selectedTalla);
      
      if (foundTalla) {
        c = foundTalla.name;
      }


      let add = arrayColores.map((a) => ({
        id: uuidv4(),
        colorId: a.id,
        color: a.name,
        sizeId: selectedTalla,
        size: c,
        amount: 0
      }));

      setInputFields([
        ...inputFields, ...add
      ]);

      let colNew = [...arrayTallasNew, { id: selectedTalla, name: c }];
      setArrayTallasNew(colNew);
    };


    function uploadMasiva(){

      filesMasiva.map((a)=>{
        const formData = new FormData();
        formData.append("file", a);
    
        axios
          .post(process.env.REACT_APP_URL_CARGA_MASIVA, formData, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            Swal.fire("Good job!", "Creado con exito", `success`);
           
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response}`,
            });
            console.log(error);
          });
      })
    }

return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            <Col md={6}>
              {user.articulos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ArticulosCreate"
                >
                  Nuevo Modelo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Modelo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuArticulos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col md={2}>
            {/* <Label>
                Foto
              </Label>
              <Input
                type="file"
                multiple
                onChange={(e) => {
                  setFilesMasiva(Array.from(e.target.files));
                }}
              />
              <Button
              onClick={uploadMasiva}
                size="sm"
                className="btn btn-success"
                id="botonListado"
              >
                Cargar
              </Button> */}
              </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
              <Button
                  size="sm"
                  className="btn"
                  color="success"
                  onClick={toggleDuplicar}
                >
                  Duplicar
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Modelos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Modelos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              {/* <td style={{ paddingTop: "0px" }}></td> */}
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={activo}
                  onChange={(e) => {
                    setActivo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                 <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigo}
                  onChange={(e) => {
                    setSelectedCodigo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedNombre}
                  onChange={(e) => {
                    setSelectedNombre(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedLinea}
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedMarca}
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedFamilia}
                  onChange={(e) => {
                    setSelectedFamilia(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {familias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColeccion}
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedComposicion}
                  onChange={(e) => {
                    setSelectedComposicion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {composiciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedProveedor}
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedEmpresa}
                  onChange={(e) => {
                    setSelectedEmpresa(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {empresas
                    .sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.name}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAno}
                  onChange={(e) => {
                    setSelectedAno(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {years.map((a) => {
                    return <option value={a}>{a}</option>;
                  })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={foto}
                  onChange={(e) => {
                    setFoto(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Todos</option>
                  <option value="Si">Con Foto</option>
                  <option value="No">Sin Foto</option>
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.piezasPorCaja}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                            a.costoUSD
                          )}{" "}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoMX)}{" "}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.venta)}{" "}
                      </td>
                      <td>{a.linea} </td>
                      <td>{a.marca} </td>
                      <td>{a.familia} </td>
                      <td>{a.coleccion} </td>
                      <td>{a.composicion} </td>
                      <td>{a.proveedor} </td>
                      <td>{a.empresa} </td>
                      <td>{a.ano} </td>
                      <td>
                      <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>
                        {user.articulos_create ? (
                          <div>
                        

                            <Button
                              size="sm"
                              className="btn"
                              color="success"
                              onClick={(e) =>
                                QRGen(
                                  a._id,
                                  a.codigo,
                                  a.nombre,
                                  a.piezasPorCaja,
                                  a.linea,
                                  a.marca,
                                  a.familia,
                                  a.coleccion,
                                  a.ano,
                                  a.composicion,
                                  a.venta
                                )
                              }
                            >
                              <i class="fas fa-qrcode"></i>
                            </Button>

                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.codigo,
                                  a.nombre,
                                  a.piezasPorCaja,
                                  a.pesoCaja,
                                  a.precioTienda,
                                  a.tamanoCaja,
                                  a.costoUSD,
                                  a.costoMX,
                                  a.venta,
                                  a.observaciones,
                                  a.idLinea,
                                  a.idMarca,
                                  a.idFamilia,
                                  a.idColeccion,
                                  a.idComposicion,
                                  a.idProveedor,
                                  a.idEmpresa,
                                  a.ano,
                                  a.EAN,
                                  a.vendedores,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>

                            <Button
                              size="sm"
                              className="btn"
                              color="success"
                              onClick={(e) =>
                                jalaProporcion(
                                  a._id,
                                  a.codigo,
                                  a.nombre,
                                  a.tallas
                                )
                              }
                            >
                              <i class="fas fa-ruler"></i>
                            </Button>

                            <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_ARTICULOS}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Ficha Tecnica
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/americanFlyArticulos/${idEditArticulo}.jpg`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una Ficha Tecnica....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Modelo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Codigo</Label>
              <Input
                type="text"
                placeholder="Codigo"
                value={codigo}
                required
                onChange={(e) => {
                  setCodigo(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Nombre</Label>
              <Input
                type="text"
                placeholder="Nombre"
                value={nombre}
                required
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Piezas Por Caja</Label>
              <Input
                type="number"
                min="0"
                step="any"
                placeholder="Piezas Por Caja"
                value={piezasPorCaja}
                required
                onChange={(e) => {
                  setPiezasPorCaja(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>EAN</Label>
              <Input
                type="text"
                placeholder="EAN"
                value={EAN}
                required
                onChange={(e) => {
                  setEAN(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Label>FOB USD</Label>
              <Input
                type="text"
                placeholder="FOB USD"
                value={costoUSD}
                required
                onChange={(e) => {
                  setCostoUSD(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Costo MX</Label>
              <Input
                type="text"
                placeholder="Costo MX"
                value={costoMX}
                required
                onChange={(e) => {
                  setCostoMX(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Venta</Label>
              <Input
                type="text"
                placeholder="Venta"
                value={venta}
                required
                onChange={(e) => {
                  setVenta(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
                <Label>Precio Tienda</Label>
                <Input
                  type="number"
                  value={precioTienda}
                  required
                  onChange={(e) => {
                    setPrecioTienda(e.target.value);
                  }}
                />
              </Col>

           
          </Row>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Linea</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedLineaEdit}
                required
                onChange={(e) => {
                  setSelectedLineaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Linea</option>
                {lineas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Marca</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedMarcaEdit}
                required
                onChange={(e) => {
                  setSelectedMarcaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Marca</option>
                {marcas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Familia</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedFamiliaEdit}
                required
                onChange={(e) => {
                  setSelectedFamiliaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Familia</option>
                {familias
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Coleccion</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedColeccionEdit}
                required
                onChange={(e) => {
                  setSelectedColeccionEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Coleccion</option>
                {colecciones
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Año</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={ano}
                required
                onChange={(e) => {
                  setAno(e.target.value);
                }}
              >
                <option value="0">Selecciona un Año</option>
                {years.map((a) => {
                  return <option value={a}>{a}</option>;
                })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Composicion</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedComposicionEdit}
                required
                onChange={(e) => {
                  setSelectedComposicionEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Composicion</option>
                {composiciones
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Proveedor</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedProveedorEdit}
                required
                onChange={(e) => {
                  setSelectedProveedorEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Proveedor</option>
                {proveedores
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>  
            <Col md={3}>
                <Label>Tamaño Caja</Label>
                <Input
                  type="text"
                  placeholder="Tamaño Caja"
                  value={tamanoCaja}
                  required
                  onChange={(e) => {
                    setTamanoCaja(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>

            <Col md={3}>
                <Label>Peso Caja</Label>
                <Input
                  type="number"
                  placeholder="Peso Caja"
                  value={pesoCaja}
                  required
                  onChange={(e) => {
                    setPesoCaja(e.target.value);
                  }}
                />
              </Col>
              
              <Col md={3}>
                <Label>Visible Vendedores</Label>
                <Input
                  type="select"
                  value={vendedores}
                  required
                  onChange={(e) => {
                    setVendedores(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="No">No</option>
                  <option value="Si">Si</option>
                </Input>
              </Col>

              <Col md={3}>
              <Label className="mr-sm-2">Empresa</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedEmpresaEdit}
                required
                onChange={(e) => {
                  setSelectedEmpresaEdit(e.target.value);
                }}
              >
                <option value="0">Selecciona una Empresa</option>
                {empresas
                  .sort((a, b) =>
                    a.name > b.name ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>  

              <Col md={3}>
              <Label>Observaciones</Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            </Row>
            <Row>

            <Col md={6}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>  
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArticulos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xxl" isOpen={modalProporcion} toggle={toggleProporcion}>
        <ModalHeader toggle={toggleProporcion}>
          <h4>
            Proporcion Modelo {codigo} / {nombre}
          </h4>
        </ModalHeader>
        <ModalBody>

        <Row>
              <Col md={2}>
              <Label>Color</Label>
              <Input 
              type="select"
              value={selectedColor}
              onChange={(e)=>{
                setSelectedColor(e.target.value)
              }}
              >
                <option value="">Selecciona</option>
              {colores
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((a)=>{
                return <option value={a._id}>{a.name}</option>
              })}
              </Input>
              </Col>

              <Col md={1}>
              <Label>Agregar</Label>
                <br />
                <Button
                color="info"
                size="sm"
                 onClick={() => {
                  addColor();
                }}>
                  <i className="fas fa-plus"></i>
                </Button>
              </Col>
              </Row>

              <Row>
              <Col md={2}>
              <Label>Talla</Label>
              <Input 
              type="select"
              value={selectedTalla}
              onChange={(e)=>{
                setSelectedTalla(e.target.value)
              }}
              >
                <option value="">Selecciona</option>
              {tallas
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((a)=>{
                return <option value={a._id}>{a.name}</option>
              })}
              </Input>
              </Col>

              <Col md={1}>
              <Label>Agregar</Label>
                <br />
                <Button
                color="info"
                size="sm"
                 onClick={() => {
                  addTalla();
                }}>
                  <i className="fas fa-plus"></i>
                </Button>
              </Col>
              </Row>

        <Row>
            <div>
            <Table>
            <thead>
                <tr>
                  <th></th>
                  {arrayColores.map(color => (
                    <th key={color.id}>{color.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {arrayTallas.map(size => (
                  <tr key={size.id}>
                    <td>{size.name}</td>
                    {arrayColores.map(color => {
                      const cellAmount = amounts.find(entry => entry.sizeId === size.id && entry.colorId === color.id)?.amount || 0;
                      return (
                        <td key={color.id}>
                          <input
                            type="number"
                            value={cellAmount}
                            onChange={(e) => handleInputChange(size.id, color.id, e.target.value)}
                            min="0"
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
              
            </Table>

            <Table>
            <thead>
                <tr>
                  <th></th>
                  {arrayColores.map(color => (
                    <th key={color.id}>{color.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {arrayTallasNew.map(size => (
                  <tr key={size.id}>
                    <td>{size.name}</td>
                    {arrayColores.map(color => {
                      const cellAmount = inputFields.find(entry => entry.sizeId === size.id && entry.colorId === color.id)?.amount || 0;
                      return (
                        <td key={color.id}>
                          <input
                            type="number"
                            value={cellAmount}
                            onChange={(e) => handleInputChangeNew(size.id, color.id, e.target.value)}
                            min="0"
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
              
            </Table>
          </div>
            </Row>
            <br />
            {/* Colores nuevos */}
            <Row>
            <div>
            <Table>
            <thead>
                <tr>
                  <th></th>
                  {arrayColoresNew.map(color => (
                    <th key={color.id}>{color.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {arrayTallas.map(size => (
                  <tr key={size.id}>
                    <td>{size.name}</td>
                    {arrayColoresNew.map(color => {
                      const cellAmount = inputFields.find(entry => entry.sizeId === size.id && entry.colorId === color.id)?.amount || 0;
                      return (
                        <td key={color.id}>
                          <input
                            type="number"
                            value={cellAmount}
                            onChange={(e) => handleInputChangeNew(size.id, color.id, e.target.value)}
                            min="0"
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}

          {arrayTallasNew.map(size => (
                  <tr key={size.id}>
                    <td>{size.name}</td>
                    {arrayColoresNew.map(color => {
                      const cellAmount = inputFields.find(entry => entry.sizeId === size.id && entry.colorId === color.id)?.amount || 0;
                      return (
                        <td key={color.id}>
                          <input
                            type="number"
                            value={cellAmount}
                            onChange={(e) => handleInputChangeNew(size.id, color.id, e.target.value)}
                            min="0"
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
            </Row>

          
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editProporcion}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalDuplicar} toggle={toggleDuplicar}>
        <ModalHeader toggle={toggleDuplicar}>
        </ModalHeader>
        <ModalBody>
<Row>
      <Col md={6}><Label>Articulo a duplicar </Label>
          <Input
          type="select"
          value={idDuplicar}
          required
          onChange={(e) => {
           jalaInfoDuplicar(e.target.value)
   
          }}
          >
          <option value="0">Selecciona</option>
          {comments
            .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
            .map((a) => {
              return <option value={a._id}>{a.codigo}</option>;
            })}

          </Input>
          </Col>
          </Row>

          <Row>
      <Col md={6}><Label>Codigo Nuevo</Label>
          <Input
          type="text"
          value={codigoDuplicar}
          required
          onChange={(e) => {
           setcodigoDuplicar(e.target.value)
          }}
          />
          </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={saveDuplicar}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoArticulos;


