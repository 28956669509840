import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";
import CurrencyInput from "react-currency-input-field";

function ListadoPendienteSurtir() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [clientesName, setClientesName] = useState([]);
  const [clientesCodigo, setClientesCodigo] = useState([]);
  const [flete, setFlete] = useState(0);

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorSurtido, setSelectedColaboradorSurtido] = useState("");
  const [comision, setComision] = useState(0);
  const [plazo, setPlazo] = useState(0);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [numero_pedido, setNumeroPedido] = useState("");

  const [inventarios, setInventarios] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [totalCajas, setTotalCajas] = useState(0);

  const [salidaBodega, setSalidaBodega] = useState("");


  const [selectedPedido, setSelectedPedido] = useState("");
  const [cliente, setCliente] = useState("");

  const [articulosPed, setArticulosPed] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [abrirSurtir, setAbrirSurtir] = useState(false);

  const [impuestos, setImpuestos] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);


  const [alertasVentasCliente, setAlertasVentasCliente] = useState("No");
  const [selectedClienteTelefono, setSelectedClienteTelefono] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [area, setArea] = useState("");

  const [calle, setCalle] = useState("")
  const [numero_ext, setNumero_ext] = useState("")
  const [numero_int, setNumero_int] = useState("")
  const [estado, setEstado] = useState("")
  const [ciudad, setCiudad] = useState("")
  const [delegacion, setDelegacion] = useState("")
  const [colonia, setColonia] = useState("")
  const [cp, setCp] = useState("")
  const [paqueteria, setPaqueteria] = useState("")
  const [RFC, setRFC] = useState("")

  const [modalAreaSurtir, setModalAreaSurtir] = useState(false);
  const toggleAreaSurtir = () => setModalAreaSurtir(!modalAreaSurtir);
  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      cantInv: 0,
      idInv: "",
      idArtPed: "",
      cajas: 0,
      piezasPorCaja: 0,
      precio: 0,
      total: 0,
      pendSurtir: 0,
      cantPed: 0,
      costoUnitario:0
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_PEDIDOS}PendienteSurtir`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
              return {
                _id: a._id,
                numero: a.idPedido,
                activo: a.is_active,
                fecha: a.fecha,
                colaboradores:
                  a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                idColaboradores: a.colaboradores[0]._id,
                observaciones: a.observaciones,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                codigo: a.clientes[0].codigo,
                idClientes: a.clientes[0]._id,
                total_cantidad: a.total_cantidad,
                status: a.status,
                autorizadoApartado: a.autorizadoApartado,
                autorizadoSurtido: a.autorizadoSurtido,
                surtido: a.surtido,
                impuestos: a.impuestos,
                editado: a.editado,
                comisionFlete: a.comisionFlete,
                comisionTDC: a.comisionTDC,
                calle: a.clientes[0].calle,
                numero_ext: a.clientes[0].numero_ext,
                numero_int: a.clientes[0].numero_int,
                pais: a.clientes[0].pais,
                estado: a.clientes[0].estado,
                ciudad: a.clientes[0].ciudad,
                delegacion: a.clientes[0].delegacion,
                colonia: a.clientes[0].colonia,
                cp: a.clientes[0].cp,
                paqueteria: a.clientes[0].paqueteria,
                RFC: a.clientes[0].RFC,
                fechaModificado: a.fechaModificado,
                telefono: a.clientes[0].telefono,
                alertasVentas: a.clientes[0].alertasVentas,
                descuento: a.descuento,
              };
            
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setComments(data);

        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresas = response.data;
        setEmpresas(allEmpresas);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });


    axios
      .get(`${URL_INVENTARIOS}SurtirCEDIS`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
    });
    doc.save(`Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    colaboradores,
    idClientes,
    clientes,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    impuestos,
    comisionFlete,
    comisionTDC,
    idPDFOC,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    codigoClientes,
    autorizadoApartado,
    autorizadoSurtido,
    telefono,
    editado,
    editPedido,
    autorizoApartado,
    autorizoSurtido
  ) {
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir2/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              idArticulo: a.articulos[0]._id,
              fotoArticulo: a.articulos[0].foto,
              cantidad: a.cantidad,
              surtido: a.surtido,
              precio: a.precio,
              total: a.total,
              pendiente_surtir: a.pendiente_surtir,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              autorizoApartado: a.pedidos[0].autorizoApartado,
              autorizoSurtido: a.pedidos[0].autorizoSurtido,
              foto: ""
            };
          })
          .filter(function (el) {
            return el != null;
          });


        let dataFinal = Object.values(arrayTabla);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let TC = dataFinal.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let iva_tabla = (TG * impuestos) / 100;

        
        dataFinal.map(async (z) => {
          if(z.fotoArticulo == "Si"){
          const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${z.idArticulo}`;
         await axios
            .get(URL_GET_MEDIA, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((data) => {
              if(data.data.secure_url != null){
              z.foto = data.data.secure_url
            }else z.foto = process.env.PUBLIC_URL + "sinImagen.png"
            })
            .catch((err) => {
              console.log(err);
            });
          return z
        }else{
          z.foto = process.env.PUBLIC_URL + "sinImagen.png"
        }
        })

        setTimeout(() => {

        const data = dataFinal.map((a) => {

    
          // totalParcial = a.cantidad * a.precio;
          // subTotalTabla = subTotalTabla + totalParcial
          // iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
          return [
            a.foto,
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cantidad) + " Pzas / " + new Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(a.cajas) + " Cajas",
            new Intl.NumberFormat("en-US").format(a.surtido),
            new Intl.NumberFormat("en-US").format(a.pendiente_surtir),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.total),
          ];
        });


        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        const columnStyles = [
          { fontSize: .000001, textColor: [255, 255, 255], cellWidth: 20, },
          { fontSize: 9, textColor: ["#000000"] },
          { fontSize: 9, textColor: ["#000000"] },
          { fontSize: 9, textColor: ["#000000"], halign: "center" },
          { fontSize: 9, textColor: ["#000000"], halign: "center" },
          { fontSize: 9, textColor: ["#000000"], halign: "right" },
          { fontSize: 9, textColor: ["#000000"], halign: "right" },
        ];

        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        
        var img2 = new Image();
         const backgroundColor = ["#d1d2d4"];
        const backgroundColorBoxLightGray = ["#e6e7e9"];
        const backgroundColorBoxBlack = ["#231f20"];
        const backgroundColorBoxPurple = ["#f28029"];
        const margins = { left: 5, right: 5 };

        img.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img, "png", 15, 5, 33, 33);

        // img.src = process.env.PUBLIC_URL + "marcas.png";
        // doc.addImage(img, "png", 9, 28, 192, 10);
        // doc.setFontSize(10);

        doc.setFillColor(...backgroundColor);
        doc.rect(5, 40, 200, 6, "F");
        doc.setTextColor("#000000");
        doc.setFontSize(10);
        doc.text(`Tel: 55 1234 5678`, 8, 44);

        doc.setTextColor("#000000");

        doc.text(`Cliente: ${codigoClientes} - ${clientes}`, 8, 52);
        doc.text(`Pedido: ${numero}`, 180, 52);

        // doc.setTextColor("#f28029");
        // doc.setFontSize(12);
        // doc.setFont("helvetica", "bold");
        // doc.text(`americanFly.mx`, 175, 44);
        // doc.setTextColor("#000000");

        doc.setFontSize(14);
        doc.setFillColor(...backgroundColorBoxBlack);
        doc.rect(100, 4, 105, 22, "F");
        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(74, 4, 25, 22, "F");
        doc.setTextColor("#ffffff");
        doc.text(`PEDIDO`, 125, 12);
        doc.setFont("helvetica", "normal");
        doc.text(`México, D. F. a ${fecha}`, 125, 22);

        doc.autoTable({
          head: [
            [
              "",
              "PRODUCTO",
              "CANTIDAD",
              "CANT. SURT.",
              // "CANT. SURTIDA",
              // "CANT. POR SURTIR",
              "CANT. X SURT.",
              // "PRECIO UNIT.",
              "PRECIO",
              "IMPORTE",
            ],
          ],
          body: dataPDFLimpia,
          bodyStyles:{
            rowHeight : 22,
            valign: "middle"
          },
          styles: {
            fontSize: 10,
            // overflow: "linebreak",
            // columnWidth: "wrap",
            // // font: 'arial',
            // overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0,
            halign: "center",
            valign: "middle",
            fontSize: 8,
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          columnStyles: columnStyles,
          theme: "grid",
          margin: { left: 5, right: 5 },
          startY: 55,


        didDrawCell: function(data) {
          if (data.column.index === 0 && data.cell.section === 'body') {
             var td = data.cell.raw;
             var img = td
            //  var dim = data.cell.height - data.cell.padding('vertical');
             var textPos = data.cell;
             doc.addImage(img, textPos.x,  textPos.y, 20, 20);
          }
        },
        
          showFoot: "lastPage",
        });

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(5, doc.autoTable.previous.finalY + 1, 36, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFontSize(8);
        doc.text(`TOTAL DE ARTICULOS`, 7, doc.autoTable.previous.finalY + 5);
        doc.setTextColor("#000000");
        doc.text(
          `________________________________________________________________________________________________________________________________`,
          5,
          doc.autoTable.previous.finalY + 22
        );

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setFont("helvetica", "normal");
        doc.rect(40, doc.autoTable.previous.finalY + 1, 40, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US").format(TK) + " Piezas",
          42,
          doc.autoTable.previous.finalY + 5
        );

        doc.setFontSize(7.5);
        doc.text(
          `1. Esta es una venta en firme y no se acepta ningún tipo de descuento o devolución sin consentimiento previo.`,
          5,
          doc.autoTable.previous.finalY + 11
        );
        doc.text(
          `2. La mercancía viaja por cuenta y riesgo del cliente, no somos responsables por faltante.`,
          5,
          doc.autoTable.previous.finalY + 14
        );
        doc.text(
          `3. El pago de la mercancía debe realizarse en tiempo y forma pactados a la cuenta proporcionada por nosotros.`,
          5,
          doc.autoTable.previous.finalY + 17
        );


        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 1, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`SUBTOTAL $`, 153, doc.autoTable.previous.finalY + 5);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 1, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(TG.toFixed(2)),
          183,
          doc.autoTable.previous.finalY + 5
        );

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 8, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`IMPUESTO $`, 154, doc.autoTable.previous.finalY + 12);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 8, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(iva_tabla.toFixed(2)),
          183,
          doc.autoTable.previous.finalY + 12
        );

        doc.setFillColor(...backgroundColorBoxPurple);
        doc.rect(144, doc.autoTable.previous.finalY + 15, 37, 6, "F");
        doc.setTextColor("#ffffff");
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        doc.text(`TOTAL $`, 159, doc.autoTable.previous.finalY + 19);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "normal");
        doc.rect(181, doc.autoTable.previous.finalY + 15, 24, 6, "F");
        doc.text(
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(total_general.toFixed(2)),
          183,
          doc.autoTable.previous.finalY + 19
        );

        var lines = doc.splitTextToSize(`Observaciones: ${observaciones}`, 100);
        doc.text(5, doc.autoTable.previous.finalY + 26, lines);

        doc.setFillColor(...backgroundColorBoxLightGray);
        doc.rect(5, doc.autoTable.previous.finalY + 40, 200, 40, "F");
        doc.setFont("helvetica", "normal");
        doc.text(
          `Confirmado y autorizado por:`,
          40,
          doc.autoTable.previous.finalY + 50
        );
        doc.text(
          `Para: ${clientes}`,
          125,
          doc.autoTable.previous.finalY + 50
        );
        doc.setFont("helvetica", "bold");
        doc.text(
          `______________________________`,
          35,
          doc.autoTable.previous.finalY + 65
        );
        doc.text(
          `______________________________`,
          120,
          doc.autoTable.previous.finalY + 65
        );
        doc.text(
          `FIRMA(S) AUTORIZADA(S)`,
          42,
          doc.autoTable.previous.finalY + 70
        );
        doc.text(
          `FIRMA(S) AUTORIZADA(S)`,
          125,
          doc.autoTable.previous.finalY + 70
        );

        doc.save(`Pedido-${numero}.pdf`);
      }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

//   function enviaMailImpuesto(
//      numero,
//     fechaHora,
//   ) {
// let fechaHora2 = moment(fechaHora).format('YYYY-MM-DD');
//     const dataIF = inputFields.map((a) => {
//       if(a.cantidad > 0){
//             return {
//               cantidad: a.cantidad,
//               articulos: a.articulos,
//               articuloNombre: a.articulosNombre,
//               cajas: a.cantidad / a.piezasPorCaja,
//               idArticulo: a.idArtPed,
//               precio: a.precio,
//               piezasPorCaja: a.piezasPorCaja
//             }
//             }
//           })
//           .filter(function (el) {
//             return el != null;
//           });

//         let dataFinal = Object.values(dataIF);

//         let TC = dataFinal.map((c) => parseFloat(c.cajas));
//         let total_cantidad = dataFinal.map((c) => c.cantidad);

//         let iva = 0;
//         let total_general = 0;

//         let STotal = inputFields.map((c) => parseFloat(c.total));
//         let subTotal = STotal.reduce((t, total, index) => t + total, 0);

//         if (impuestos != 0) {
//           iva = (impuestos * subTotal) / 100;
//         } else {
//           iva = 0;
//         }

//         total_general = (impuestos * subTotal) / 100 + subTotal + comisionFlete + comisionTDC;

//         let totalParcial = 0;

//         const data = dataFinal.map((a) => {
//           totalParcial = a.cantidad * a.precio;
//           return [
//             new Intl.NumberFormat("en-US").format(a.cantidad),
//             codigoArticulo,
//             articuloNombre,
//             new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
//             new Intl.NumberFormat("en-US", {
//               style: "currency",
//               currency: "USD",
//               maximumFractionDigits: 2,
//             }).format(a.precio),
//             new Intl.NumberFormat("en-US", {
//               style: "currency",
//               currency: "USD",
//               maximumFractionDigits: 2,
//             }).format(totalParcial),
//           ];
//         });

//         const dataPDF = Object.values(data);
//         const dataPDFLimpia = dataPDF.filter(function (el) {
//           return el != null;
//         });

//          const columnStyles = [
//         { fontSize: 9,textColor: ["#000000"] },
//         { fontSize: 9,textColor: ["#000000"] },
//         { fontSize: 9,textColor: ["#000000"] },
//         { fontSize: 9,textColor: ["#000000"] },
//         { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
//         { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
//         ];
        
//       const doc = new jsPDF({compressPdf: true});
//         var img = new Image();
//         var img2 = new Image();
//         const backgroundColor = ["#d1d2d4"];
//         const backgroundColorBoxLightGray = ["#e6e7e9"];
//         const backgroundColorBoxBlack = ["#231f20"];
//         const backgroundColorBoxPurple = ["#f28029"];
//         const margins = {  left: 5, right: 5 };

//       img.src = process.env.PUBLIC_URL + "logoInstitucion.jpg";
//       doc.addImage(img, "jpg", 15, 5, 33, 33);

//       // img.src = process.env.PUBLIC_URL + "marcas.jpg";
//       // doc.addImage(img, "jpg", 9, 28, 192, 10);
//       // doc.setFontSize(10);
      
//       doc.setFillColor(...backgroundColor);
//       doc.rect(5, 40, 200, 6, 'F');
//       doc.setTextColor("#000000");
//       doc.text(`Tel: 55 1234 5678`,8, 44);

//       doc.setTextColor("#000000");
//       doc.text(`Cliente: ${clientesCodigo} - ${clientesName}`,8, 52);
//       doc.text(`Remisión: ${numero}`,80, 52);
//       doc.text(`Pedido: ${numero_pedido}`,160, 52);

//       doc.setTextColor("#f28029");
//       doc.setFontSize(12);
//       doc.setFont('helvetica', 'bold');
//       doc.text(`americanFly.mx`,175, 44);
//       doc.setTextColor("#000000");

//       doc.setFontSize(14);
//       doc.setFillColor(...backgroundColorBoxBlack);
//       doc.rect(100, 4, 105, 22, 'F');
//       doc.setFillColor(...backgroundColorBoxPurple);
//       doc.rect(74, 4, 25, 22, 'F');
//       doc.setTextColor("#ffffff");
//       doc.text(`NOTA DE SALIDA`,125, 12);
//       doc.setFont('helvetica', 'normal');
//       doc.text(`SALIDA BODEGA`,125, 17);
//       doc.text(`México, D. F. a ${fechaHora2}`,125, 22);
        
//         doc.autoTable({
//           head: [[
//               "CANTIDAD",
//               "MODELO",
//               "DESCRIPCION",
//               "BULTO#",
//               "PRECIO UNITARIO",
//               "IMPORTE",
//             ]],
//           body: dataPDFLimpia,
//           styles: {
//             fontSize: 10,
//             overflow: "linebreak",
//             columnWidth: "wrap",
//             overflowColumns: "linebreak",
//           },
//           headStyles: {
//             fillColor: ["#f28029"],
//             lineWidth: 0,
//             halign: 'center',
//             valign: 'middle',
//             fontSize: 8
//           },
//           footStyles: {
//             fillColor: ["#f28029"],
//           },
//           columnStyles: columnStyles,
//           theme:"grid",
//           margin: { left: 5, right: 5 },
//           startY: 55,
//           showFoot: "lastPage",
//         });

//         doc.setFillColor(...backgroundColorBoxPurple);
//         doc.rect(5, doc.autoTable.previous.finalY +1, 36, 6, 'F');
//         doc.setTextColor("#ffffff");
//         doc.setFontSize(8);
//         doc.text(`TOTAL DE ARTICULOS`,7,  doc.autoTable.previous.finalY+5);
//         doc.setTextColor("#000000");
//         doc.text(`________________________________________________________________________________________________________________________________`,5,  doc.autoTable.previous.finalY+22);

//         doc.setFillColor(...backgroundColorBoxLightGray);
//         doc.setFont('helvetica', 'normal');
//         doc.rect(40, doc.autoTable.previous.finalY +1, 40, 6, 'F');
//         doc.text(new Intl.NumberFormat("en-US").format(total_cantidad) + " Piezas",42,  doc.autoTable.previous.finalY+5);

//         doc.setFontSize(7.5);
//         doc.text(`1. Esta es una venta en firme y no se acepta ningún tipo de descuento o devolución sin consentimiento previo.`,5,  doc.autoTable.previous.finalY+11);
//         doc.text(`2. La mercancía viaja por cuenta y riesgo del cliente, no somos responsables por faltante.`,5,  doc.autoTable.previous.finalY+14);
//         doc.text(`3. El pago de la mercancía debe realizarse en tiempo y forma pactados a la cuenta proporcionada por nosotros.`,5,  doc.autoTable.previous.finalY+17);

//         var nota = "Debo(emos)  y pagare(mos)  incondicionalmente por  este  pagare a la orden  de AMERICAN FLY en esta plaza,  el día estipulado en  este documento,  la cantidad estipulada  en este  documento,  valor recibido a  mi entera satisfacción, este pagare causará intereses a razón de 2% mensual a partir de la fecha de vencimiento, hasta su total liquidación, pagadero conjuntamente con el principal. Este pagare se rige en los términos de ley general de títulos y operaciones de crédito.";
//         var lines = doc.splitTextToSize(nota, 129);
//         doc.text(5,doc.autoTable.previous.finalY + 26, lines);

//         doc.setFillColor(...backgroundColorBoxPurple);
//         doc.rect(144, doc.autoTable.previous.finalY +1, 37, 6, 'F');
//         doc.setTextColor("#ffffff");
//         doc.setFont('helvetica', 'bold');
//         doc.setFontSize(8);
//         doc.text(`SUBTOTAL $`,153,  doc.autoTable.previous.finalY+5);

//         doc.setFillColor(...backgroundColorBoxLightGray);
//         doc.setTextColor("#000000");
//         doc.setFont('helvetica', 'normal');
//         doc.rect(181, doc.autoTable.previous.finalY +1, 24, 6, 'F');
//         doc.text(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((subTotal).toFixed(2)),183,  doc.autoTable.previous.finalY+5);

//         doc.setFillColor(...backgroundColorBoxPurple);
//         doc.rect(144, doc.autoTable.previous.finalY +8, 37, 6, 'F');
//         doc.setTextColor("#ffffff");
//         doc.setFont('helvetica', 'bold');
//         doc.setFontSize(8);
//         doc.text(`IMPUESTO $`,154,  doc.autoTable.previous.finalY+12);

//         doc.setFillColor(...backgroundColorBoxLightGray);
//         doc.setTextColor("#000000");
//         doc.setFont('helvetica', 'normal');
//         doc.rect(181, doc.autoTable.previous.finalY +8, 24, 6, 'F');
//         doc.text(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format((iva).toFixed(2)),183,  doc.autoTable.previous.finalY+12);

//         doc.setFillColor(...backgroundColorBoxPurple);
//         doc.rect(144, doc.autoTable.previous.finalY +15, 37, 6, 'F');
//         doc.setTextColor("#ffffff");
//         doc.setFont('helvetica', 'bold');
//         doc.setFontSize(8);
//         doc.text(`TOTAL $`,159,  doc.autoTable.previous.finalY+19);

//         doc.setFillColor(...backgroundColorBoxLightGray);
//         doc.setTextColor("#000000");
//         doc.setFont('helvetica', 'normal');
//         doc.rect(181, doc.autoTable.previous.finalY +15, 24, 6, 'F');
//         doc.text(new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(parseFloat(total_general).toFixed(2)),183,  doc.autoTable.previous.finalY+19);

//         var lines = doc.splitTextToSize(`Observaciones: ${observaciones}`, 40);
//         doc.text(145,doc.autoTable.previous.finalY + 26, lines);

//         doc.setFont('helvetica', 'bold');
//         doc.text(`Nombre Completo:__________________________________________________________________________  Firma:______________________________`,5,  doc.autoTable.previous.finalY+50);

//         doc.setFont('helvetica', 'normal');
//         doc.setFontSize(6);
//         doc.text(`ESTE DOCUMENTO NO REPRESENTA UN COMPROBANTE FISCAL`,67,  doc.autoTable.previous.finalY+60);
// // doc.save(`Nota-${numero}.pdf`);

//       var att = doc.output("arraybuffer");
//       var base64File = encode(att);
//       axios
//       .post(
//         URL_SEND_MAIL_ATT,
//         {
//           subject: "Solicitud de factura",
//           email: process.env.REACT_APP_MAIL_ITAMERICANFLY,
//           fileName: `Nota-${numero}.pdf`,
//           att: base64File,
//           text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
//          <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
//          <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
//          <tbody>
//          <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
//          <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Le solicitamos la realizar la factura con la informacion adjunta.</tr>
//          </tbody>
//          <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
//          </table>`,
//         },
//         {
//           headers: {
//             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//           },
//         }
//       )
//       .then(() => {
//         console.log('email enviado')
//         Swal.fire("Good job!", "Enviado con exito", "success");
//       })
//       .catch((error) => {
//         console.log('email error')
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: "Something went wrong!",
//         });
//         console.log(error);
//       });
//   }

async  function excelOC(
    idPDFOC,
    clientes,
    ) {

      await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir/${idPDFOC}/${selectedArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data.AP;
        let allInventarios = response.data.AI;

        let allInventarios2 = allInventarios
          .map((a) => {
            return {
              idArticulo: a.articulos[0]._id,
              articulo: a.articulos[0].nombre + " " + a.articulos[0].lineas[0].name + " " + a.articulos[0].familias[0].name,
              cantidadInventario: a.cantidad,
              cantidadPedido: 0,
              surtido: 0,
              pendiente_surtir: 0,
              cajas: 0,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataInv = Object.values(allInventarios2);

        let agrupado = dataInv
          .reduce(function (groups, item) {
            const val = item["idArticulo"];
            groups[val] = groups[val] || {
              idArticulo: item.idArticulo,
              cajas: 0,
            };
            groups[val].articulo = item.articulo;
            groups[val].cantidadPedido = item.cantidadPedido;
            groups[val].surtido = item.surtido;
            groups[val].pendiente_surtir = item.pendiente_surtir;
            groups[val].cajas = item.cajas;
            groups[val].piezasPorCaja = item.piezasPorCaja;
            return groups;
          }, []);

        let dataAgrupado = Object.values(agrupado);

        let allArticulosPedido2 = allArticulosPedido
          .map((a) => {
            return {
              articulo: a.articulos[0].nombre + " " + a.articulos[0].lineas[0].name + " " + a.articulos[0].familias[0].name,
              idArticulo: a.articulos[0]._id,
              cantidadPedido: a.cantidad,
              surtido: a.surtido,
              pendiente_surtir: a.pendiente_surtir,
              cajas:
                parseFloat(a.pendiente_surtir) /
                parseFloat(a.articulos[0].piezasPorCaja),
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataPedidos = Object.values(allArticulosPedido2);



    const dataExcel = dataPedidos.map((a) => {
        return {
          Cliente: clientes,
          Articulo: a.articulo,
          PzsCaja: a.piezasPorCaja,
          Cajas: a.cajas,
          PendSurtir: a.pendiente_surtir,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "DesglocePedido";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "DesglocePedido",
        sheetFilter: [
        "Cliente",
        "Articulo", 
        "PzsCaja", 
        "Cajas", 
        "PendSurtir", 
        ],
        sheetHeader: [
        "Cliente",
        "Articulo", 
        "PzsCaja", 
        "Cajas", 
        "PendSurtir", 
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Ultima Mod", field: "fechaModificacion", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "PDF", field: "pdf", sortable: false },
    { name: "Surtir", field: "surtir", sortable: false },
    { name: "Surtido", field: "surtido", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  // save
  const saveSurtidos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    let iva = 0;
    let TT = 0;

    let STotal = inputFields.map((c) => parseFloat(c.total));
    let ST = STotal.reduce((t, total, index) => t + total, 0);

    let STDESC = ST - descuento

    if (impuestos != 0) {
      iva = (impuestos * STDESC) / 100;
    } else {
      iva = 0;
    }
    let temp1 = (ST * descuento) / 100;

    TT = (impuestos * STDESC) / 100 + ST + parseFloat(flete) + comisionTDC - temp1

    try {
      await axios
        .post(
          URL_SURTIDOS,
          {
            fecha,
            pedidos: selectedPedido,
            observaciones,
            user: user.id,
            clientes: cliente,
            areas: selectedArea,
            total_general: parseFloat(TT).toFixed(2),
            saldo: parseFloat(TT).toFixed(2),
            subTotal: parseFloat(ST).toFixed(2),
            iva: parseFloat(iva).toFixed(2),
            impuestos,
            is_active:"Si",
            colaboradores:selectedColaboradorSurtido,
            comisionPagada:"No",
            fotoEvidencia:"No",
            mail:"No",
            salidaBodega,
            plazo,
            comision,
            descuento,
            total_cantidad:total,
            noGuia: "Sin Guia",
            noFactura: "Sin Factura",
            empresas: selectedEmpresa,
            flete
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          if(data.data.impuestos ==16){
              // enviaMailImpuesto(
              //   data.data.idSurtido,
              //   data.data.updatedAt,
              // );
            }

          inputFields.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_SURTIDOS,
                  {
                    surtidos: data.data._id,
                    inventarios: a.idInv,
                    cantidad: parseFloat(a.cantidad),
                    articulos: a.articulos,
                    pedidos: selectedPedido,
                    articulosPedido: a.idArtPed,
                    areas: selectedArea,
                    clientes: cliente,
                    fecha,
                    costoUnitario: a.costoUnitario,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    if(alertasVentasCliente == "Si"){
                    PDFOCWA(data.data.idSurtido, parseFloat(ST).toFixed(2), parseFloat(iva).toFixed(2), parseFloat(TT).toFixed(2))
                    }
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Surtido",
                          detalle: `${numero_pedido} ${selectedArea} ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                if(alertasVentasCliente == "Si"){
                  PDFOCWA(data.data.idSurtido, parseFloat(ST).toFixed(2), parseFloat(iva).toFixed(2), parseFloat(TT).toFixed(2))
                  }
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Surtido",
                      detalle: `${numero_pedido} ${selectedArea} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        idArtPed: "",
        cajas: 0,
        piezasPorCaja: 0,
        precio: 0,
        total: 0,
        pendSurtir: 0,
        cantPed: 0,
        costoUnitario:0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if(i.cantInv >= event.target.value ){
        // if (
        //   (i.cantInv >= event.target.value &&
        //     i.pendSurtir >= event.target.value) ||
        //   event.target.value == ""
        // ) {
          i[event.target.name] = event.target.value;
          i.cajas =
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
          i.total = parseFloat(event.target.value) * parseFloat(i.precio);
        }else{
          i.cantidad = 0
            i.cajas = 0
            i.total = 0
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede el inventario!",
          });
        }
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "La cantidad excede tu pedido!",
        //     showConfirmButton: false,
        //   });
        //   i.cajas = 0;
        //   i.cantidad = 0;
        // }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if(i.cantInv >= (event.target.value * i.piezasPorCaja) ){
        // let cantTemp =
        //   parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        // if (
        //   (i.cantInv >= cantTemp && i.pendSurtir >= cantTemp) ||
        //   event.target.value == ""
        // ) {
          i[event.target.name] = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          i.total =
            parseFloat(event.target.value) *
            parseFloat(i.piezasPorCaja) *
            parseFloat(i.precio);
          }else{
            i.cantidad = 0
              i.cajas = 0
              i.total = 0
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "La cantidad excede el inventario!",
            });
          }
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "La cantidad excede tu pedido!",
        //     showConfirmButton: false,
        //   });
        //   i.cajas = 0;
        //   i.cantidad = 0;
        // }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inventarios.filter((e) => e.idArticulo == event.target.value).length == 1
    ) {
      let artPed = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.idArtPed;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let pendSurtir = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.pendSurtir;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let precio = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.precio;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario
          handleChangeInputArticuloUnico(
            id,
            idArticulo,
            piezasPorCaja,
            cantInv,
            idInv,
            artPed,
            precio,
            pendSurtir,
            costoUnitario
          );
        }
      });
    } else {
      let artPed = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.idArtPed;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let pendSurtir = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.pendSurtir;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let precio = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.precio;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            artPed,
            precio,
            pendSurtir,
            costoUnitario
          );
        }
      });
    }
  }
  const handleChangeInputArticuloUnico = (
    id,
    idArticulo,
    piezasPorCaja,
    cantInv,
    idInv,
    artPed,
    precio,
    pendSurtir,
    costoUnitario
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.idArtPed = artPed[0];
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.precio = parseFloat(precio);
        i.pendSurtir = parseFloat(pendSurtir);
        i.costoUnitario = costoUnitario
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    artPed,
    precio,
    pendSurtir
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.idArtPed = artPed[0];
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.precio = parseFloat(precio);
        i.pendSurtir = parseFloat(pendSurtir);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };



  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);

    let cantCajas = inputFields.map((c) => parseFloat(c.cajas));
    let TTC = cantCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TTC);
  }

  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsArticulos.includes(event.target.value)) {
        let artPed = articulosPed
          .map((a) => {
            if (a.articulos == event.target.value) {
              return a.idArtPed;
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let precio = articulosPed
          .map((a) => {
            if (a.articulos == event.target.value) {
              return a.precio;
            }
          })
          .filter(function (el) {
            return el != null;
          });

        inventarios.map((a) => {
          if (a.idArticulo == event.target.value) {
            let idArticulo = a.idArticulo;
            let piezasPorCaja = a.piezasPorCaja;
            let costoUnitario = a.costoUnitario
            handleChangeInputArticulo(
              id,
              idArticulo,
              piezasPorCaja,
              artPed,
              precio,
              costoUnitario
            );
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en tu pedido",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  async function seleccionarPedido(
    id,
    numero,
    idClientes,
    clientes,
    codigo,
    impuestos,
    comisionFlete,
    comisionTDC,
    descuento,
    idColaboradores,
    alertasVentas,
    telefono,
    vendedor,
    calle,
    numero_ext,
    numero_int,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    paqueteria,
    RFC,
  ) {
    setSelectedPedido(id);
    setNumeroPedido(numero);
    setCliente(idClientes);
    setImpuestos(impuestos);
    setComisionTDC(comisionTDC);
    setClientesName(clientes);
    setClientesCodigo(codigo);
    setDescuento(descuento)
    setAlertasVentasCliente(alertasVentas);
    setSelectedClienteTelefono(telefono);
    setVendedor(vendedor);
    setArea(area);
    setCalle(calle);
    setNumero_ext(numero_ext);
    setNumero_int(numero_int);
    setEstado(estado);
    setCiudad(ciudad);
    setDelegacion(delegacion);
    setColonia(colonia);
    setCp(cp);
    setPaqueteria(paqueteria);
    setRFC(RFC);
    setSelectedColaboradorSurtido(idColaboradores);
    setFlete(comisionFlete)

    toggleAreaSurtir()
  }

  function jalaArticulos(){
    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        idArtPed: "",
        cajas: 0,
        piezasPorCaja: 0,
        precio: 0,
        total: 0,
        pendSurtir: 0,
        cantPed: 0,
        costoUnitario:0
      },
    ])
    axios
    .get(`${URL_ARTICULOS_PEDIDO}/surtir3/${selectedPedido}/${selectedArea}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allArticulosPed = res.data;
      setArticulosPed(allArticulosPed);

      let arrayInputFields = allArticulosPed
        .map((a) => {
          return {
            id: uuidv4(),
            articulos: a.articulos,
            cantidad: 0,
            cantInv: a.cantInv,
            idInv: a.idInv,
            idArtPed: a.idArtPed,
            cajas: 0,
            piezasPorCaja: a.piezasPorCaja,
            precio: a.precio,
            total: 0,
            pendSurtir: a.pendSurtir,
            articuloNombre: a.articuloNombre,
            articuloCodigo: a.articuloCodigo,
            cantPed: a.cantPed,
            costoUnitario: a.costoUnitario
          };
        })
        .sort((a, b) => (a.articuloNombre > b.articuloNombre ? 1 : -1));

      let dataIF = Object.values(arrayInputFields);

      setInputFields(dataIF);

      let result = allArticulosPed.map((a) => a.articulos);
      setIdsArticulos(result);

    })
    .catch((err) => {
      console.log(err);
    });
    setModalAreaSurtir(false);
  setAbrirSurtir(true);
  }

function PDFOCWA(idSurtido, subTotal, iva, total){


  const data = inputFields.map((a) => {
    return [
      a.articuloNombre,
      new Intl.NumberFormat("en-US",{maximumFractionDigits: 2}).format(a.cajas),
      new Intl.NumberFormat("en-US").format(a.cantidad),
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        
      }).format(a.precio),
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        
      }).format(a.total),
    ];
  });

  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF({compressPdf: true});
  var img = new Image();
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 10, 11, 20, 5);

  doc.setFontSize(12);
  doc.text(`Fecha:`, 35, 15);
  doc.text(`${fecha}`, 60, 15);
  doc.text(`Nota:`, 35, 20);
  doc.text(`${idSurtido}`, 60, 20);
  doc.text(`Pedido:`, 35, 25);
  doc.text(`${numero_pedido}`, 60, 25);

  doc.text(`Ejecutivo:`, 120, 15);
  doc.text(`${vendedor}`, 150, 15);

  doc.setFontSize(8);
  doc.text(`Cliente: ${clientesCodigo} - ${clientesName}`, 10, 32);
  doc.text(`RFC: ${RFC}`, 150, 32);
  doc.text(
    `Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int} Telefono: ${selectedClienteTelefono}`,
    10,
    42
  );
  doc.text(
    `Edo: ${estado}, Ciudad: ${ciudad}, Del: ${delegacion}, Col: ${colonia}, CP: ${cp} Paqueteria: ${paqueteria}`,
    10,
    47
  );

  doc.autoTable({
    head: [["Articulo", "Cajas", "Cantidad", "Precio", "Total"]],
    body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
    styles: {
      fontSize: 8,
      overflow: "linebreak",
      columnWidth: "wrap",
      // font: 'arial',
      overflowColumns: "linebreak",
    },
    margin: { left: 5, right: 5 },
    startY: 50,
    foot: [
      [
        "",
        new Intl.NumberFormat("en-US").format(totalCajas.toFixed(2)) + " Cajas",
        new Intl.NumberFormat("en-US").format(total) + " Piezas",
        "Sub Total",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          
        }).format(subTotal),
      ],
      [
        "",
        "",
        "",
        `Comision`,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          
        }).format(iva),
      ],
      [
        "",
        "",
        "",
        "Comision TDC",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          
        }).format(comisionTDC),
      ],
      [
        "",
        "",
        "",
        "Comision Flete",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          
        }).format(flete),
      ],
      [
        "",
        "",
        "",
        "Total",
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
          
        }).format(total),
      ],
    ],
    showFoot: "lastPage",
  });

  var att = doc.output("arraybuffer");

  let params = new URLSearchParams( {
    number: selectedClienteTelefono,
      message:`Hola ${clientesName}, Estamos preparando tu pedido. Gracias por confiar en ${process.env.REACT_APP_NOMBRE_INSTITUCION}, estamos para servirte!`,
      fileName:`Nota-${idSurtido}.pdf`,
    mimetype: "application/pdf",
  })

  axios
    .post(
      `${URL_WHATSAPP}SendPDFFront?${params.toString()}`,
      att,
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem(
            "app_token"
          )}`,
          "Content-Type": "application/octet-stream",
        },
      }
    )
}
  return (
    <>
      <Header />
      <br />
      <br />
      {user.surtidos_create ? (
        <div className="card col-12">
          {!abrirSurtir ? (
            <div>
              <Row>
                <Col md={8}>
                  <Button
                    size="sm"
                    href="/MenuVentas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>Enviar Listado Pendiente Surtir</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                    Enviar
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">Pendientes de Surtir</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>

              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Clientes</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedCliente}
                    onChange={(e) => {
                      setSelectedCliente(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {clientes
                      .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Vendedor</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedColaborador}
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona un Vendedor</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {commentsData.map((a) => {
                    if (a.editado == "Si") {
                      return (
                        <tr style={{ backgroundColor: "orange" }}>
                          <td>{a.numero}</td>
                          <td>{a.fechaModificado}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>
                              <div>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) =>
                                    PDFOC(
                                      a.fecha,
                                        a.colaboradores,
                                        a.idClientes,
                                        a.clientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.impuestos,
                                        a.comisionFlete,
                                        a.comisionTDC,
                                        a._id,
                                        a.calle,
                                        a.numero_ext,
                                        a.numero_int,
                                        a.pais,
                                        a.estado,
                                        a.ciudad,
                                        a.delegacion,
                                        a.colonia,
                                        a.cp,
                                        a.RFC,
                                        a.paqueteria,
                                        a.codigoClientes,
                                        a.autorizadoApartado,
                                        a.autorizadoSurtido,
                                        a.telefono,
                                        a.editado,
                                        a.editPedido,
                                        a.autorizoApartado,
                                        a.autorizoSurtido
                                    )
                                  }
                                >
                                  <i class="far fa-file-pdf"></i>
                                </Button>

                                <Button
                                  size="sm"
                                  className="btn"
                                  color="primary"
                                  onClick={(e) =>
                                    excelOC(
                                      a._id,
                                      a.clientes,
                                    )
                                  }
                                >
                                  <i class="far fa-file-excel"></i>
                                </Button>

                              </div>
                          </td>
                          <td>
                          {a.surtido == "No" ? (
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarPedido(
                                  a._id,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.codigo,
                                  a.impuestos,
                                  a.comisionFlete,
                                  a.comisionTDC,
                                  a.descuento,
                                  a.idColaboradores,
                                  a.alertasVentas,
                                  a.telefono,
                                  a.colaboradores,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.paqueteria,
                                  a.RFC,
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                          ):undefined}
                            
                          </td>
                          <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.surtido}
                              URL_STATUS={URL_PEDIDOS}
                              hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                            />
                            {"  "}Modificado
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td>{a.numero}</td>
                          <td>{a.fechaModificado}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>
                              <div>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) =>
                                    PDFOC(
                                      a.fecha,
                                        a.colaboradores,
                                        a.idClientes,
                                        a.clientes,
                                        a.total_cantidad,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.impuestos,
                                        a.comisionFlete,
                                        a.comisionTDC,
                                        a._id,
                                        a.calle,
                                        a.numero_ext,
                                        a.numero_int,
                                        a.pais,
                                        a.estado,
                                        a.ciudad,
                                        a.delegacion,
                                        a.colonia,
                                        a.cp,
                                        a.RFC,
                                        a.paqueteria,
                                        a.codigoClientes,
                                        a.autorizadoApartado,
                                        a.autorizadoSurtido,
                                        a.telefono,
                                        a.editado,
                                        a.editPedido,
                                        a.autorizoApartado,
                                        a.autorizoSurtido
                                    )
                                  }
                                >
                                  <i class="far fa-file-pdf"></i>
                                </Button>

                                <Button
                                  size="sm"
                                  className="btn"
                                  color="primary"
                                  onClick={(e) =>
                                    excelOC(
                                      a._id,
                                      a.clientes,
                                    )
                                  }
                                >
                                  <i class="far fa-file-excel"></i>
                                </Button>

                              </div>
                          </td>
                          <td>
                          {a.surtido == "No" ? (
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarPedido(
                                  a._id,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.codigo,
                                  a.impuestos,
                                  a.comisionFlete,
                                  a.comisionTDC,
                                  a.descuento,
                                  a.idColaboradores,
                                  a.alertasVentas,
                                  a.telefono,
                                  a.colaboradores,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.paqueteria,
                                  a.RFC,
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                          ):undefined}
                          </td>
                          <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.surtido}
                              URL_STATUS={URL_PEDIDOS}
                              hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          ) : (
            // Termina Abrir Surtir
            <div className="card container col-md-12">
              <h4>Surtir Pedido {numero_pedido}</h4>
              <h4>
                Cliente: {clientesCodigo} {clientesName}
              </h4>

              <Form onSubmit={saveSurtidos}>
                <Row>
                  <Col md={2}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                  <Label>Flete</Label>
                  <Input 
                  type="number"
                  value={flete}
                  required
                  onChange={(e) => {
                    setFlete(e.target.value);
                  }}
                  />
                  </Col>

                  <Col md={2}>
                    <Label>Impuestos %</Label>
                     <Input
                    type="select"
                    value={impuestos}
                    required
                    onChange={(e) => {
                      setImpuestos(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="16">Si</option>
                    <option value="0">No</option>
                </Input>
                  </Col>


                  <Col md={2}>
                    <Label>Descuento %</Label>
                    <Input
                      type="number"
                      value={descuento}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                     Salida Bodega
                    </Label>
                    <Input
                      type="text"
                      placeholder="Salida Bodega"
                      value={salidaBodega}
                      required
                      onChange={(e) => {
                        setSalidaBodega(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Vendedor</Label>
                    <Input
                      type="select"
                      value={selectedColaboradorSurtido}
                          onChange={(e) => {
                        setSelectedColaboradorSurtido(e.target.value);
                      }}
                    >
                      <option value="">Selecciona un Vendedor</option>
                      {colaboradores
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>Comision</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={comision}
                      className={`form-control`}
                      onValueChange={(value) => {
                        setComision(value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Plazo</Label>
                    <Input
                      type="select"
                      placeholder="Plazo"
                      value={plazo}
                      required
                      onChange={(e) => {
                        setPlazo(e.target.value);
                      }}
                    >
                      <option value="">Plazo</option>
                      <option value="0">0</option>
                      <option value="30">30</option>
                      <option value="60">60</option>
                      <option value="90">90</option>
                      <option value="120">120</option>
                      <option value="150">150</option>
                      <option value="180">180</option>
                    </Input>
                  </Col>
                </Row>

                <br />
                {/* Tabla Articulos */}

                <Row>
                  <Col md={2}>
                    <Label className="mr-sm-2">Codigo</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Modelo</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cant Pedido</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pend Surtir</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Inventario</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cajas</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pzas Caja</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Total</Label>
                  </Col>
                </Row>

                {inputFields.map((inputField) => (
                  <div key={inputField.id}>
                    <Row>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="string"
                          value={inputField.articuloCodigo}
                          // onChange={(event) => {
                          //   BuscaCodigo(inputField.id, event);
                          // }}
                          disabled
                        ></Input>
                      </Col>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="select"
                          value={inputField.articulos}
                          required
                          // onChange={(event) => {
                          //   BuscaArticulo(inputField.id, event);
                          // }}
                          disabled
                        >
                          <option value="">Selecciona un Articulo</option>
                          {articulosPed
                            .sort((a, b) =>
                              a.articuloNombre > b.articuloNombre ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a.articulos}>
                                  {a.articuloNombre}
                                </option>
                              );
                            })}
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="precio"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Precio"
                          value={inputField.precio}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantPed"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cant Ped"
                          value={inputField.cantPed}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="pendSurtir"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.pendSurtir}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantInv"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantInv}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cajas"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cajas"
                          value={inputField.cajas}
                          onChange={(event) =>
                            handleChangeInputCantidadCajas(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="piezasPorCaja"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Piezas por Caja"
                          value={inputField.piezasPorCaja}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="precio"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Precio"
                          value={inputField.precio * inputField.cantidad}
                          disabled
                        />
                      </Col>
                      {/* <Col>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </Col> */}
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col md={10}>
                    <h4 id="logoutBoton">
                      TOTALES {totalCajas} Cajas {total} Pzas.
                    </h4>
                  </Col>
                </Row>
                {/* Termina Tabla Articulos */}

                <br />
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirSurtir(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          )}
        </div>
      ) : undefined}
      <br />

     <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

          <Modal size="sm" isOpen={modalAreaSurtir} toggle={toggleAreaSurtir}>
            <ModalHeader toggle={toggleAreaSurtir}>
              <h4>Surtir Pedido {numero_pedido}</h4>
            </ModalHeader>
            <ModalBody>
           <Row>
            <Col md={6}>
            <Label>Almacen</Label>
            <Input
            type="select"
            value={selectedArea}
            onChange={(event) => setSelectedArea(event.target.value)}
            >
             <option value="">Selecciona</option>
                    {areas
                      .sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.name}</option>
                        );
                      })}
            </Input>
            </Col>

            <Col md={6}>
            <Label>Empresa</Label>
            <Input
            type="select"
            value={selectedEmpresa}
            onChange={(event) => setSelectedEmpresa(event.target.value)}
            >
             <option value="">Selecciona</option>
                    {empresas
                      .sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.name}</option>
                        );
                      })}
            </Input>
            </Col>

           </Row>
           <Button type="submit" className="btn btn-success" onClick={e=>{jalaArticulos()}}>
                    Surtir
                  </Button>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoPendienteSurtir;
